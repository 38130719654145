import React from 'react';
import Helmet from 'react-helmet';
import robotoRegular from '@fonts/roboto-v20-latin-regular.woff2';
import roboto500 from '@fonts/roboto-v20-latin-500.woff2';
import roboto700 from '@fonts/roboto-v20-latin-700.woff2';

export function FontPreloadPageHelmet(): JSX.Element {
    return (
        <Helmet>
            <link rel='preload' href={robotoRegular} as='font' crossOrigin='anonymous' />
            <link rel='preload' href={roboto500} as='font' crossOrigin='anonymous' />
            <link rel='preload' href={roboto700} as='font' crossOrigin='anonymous' />
        </Helmet>
    );
}
