export const af = 'af';
export const am = 'am';
export const arDz = 'ar-dz';
export const arIq = 'ar-iq';
export const arKw = 'ar-kw';
export const arLy = 'ar-ly';
export const arMa = 'ar-ma';
export const arSa = 'ar-sa';
export const artn = 'ar-tn';
export const ar = 'ar';
export const az = 'az';
export const be = 'be';
export const bg = 'bg';
export const bi = 'bi';
export const bm = 'bm';
export const bnBd = 'bn-bd';
export const bn = 'bn';
export const bo = 'bo';
export const br = 'br';
export const bs = 'bs';
export const ca = 'ca';
export const cs = 'cs';
export const cv = 'cv';
export const cy = 'cy';
export const da = 'da';
export const deAt = 'de-at';
export const deCh = 'de-ch';
export const de = 'de';
export const dv = 'dv';
export const el = 'el';
export const enAu = 'en-au';
export const enCa = 'en-ca';
export const enGb = 'en-gb';
export const enIe = 'en-ie';
export const enIl = 'en-il';
export const enIn = 'en-in';
export const enNz = 'en-nz';
export const enSg = 'en-sg';
export const enTt = 'en-tt';
export const en = 'en';
export const eo = 'eo';
export const esDo = 'es-do';
export const es = 'es';
export const et = 'et';
export const eu = 'eu';
export const fa = 'fa';
export const fi = 'fi';
export const fo = 'fo';
export const frCa = 'fr-ca';
export const frCh = 'fr-ch';
export const fr = 'fr';
export const fy = 'fy';
export const ga = 'ga';
export const gd = 'gd';
export const gl = 'gl';
export const gomLatn = 'gom-latn';
export const gu = 'gu';
export const he = 'he';
export const hi = 'hi';
export const hr = 'hr';
export const ht = 'ht';
export const hu = 'hu';
export const hyAm = 'hy-am';
export const id = 'id';
export const is = 'is';
export const itCh = 'it-ch';
export const it = 'it';
export const ja = 'ja';
export const jv = 'jv';
export const ka = 'ka';
export const kk = 'kk';
export const km = 'km';
export const kn = 'kn';
export const ko = 'ko';
export const ku = 'ku';
export const ky = 'ky';
export const lb = 'lb';
export const lo = 'lo';
export const lt = 'lt';
export const lv = 'lv';
export const me = 'me';
export const mi = 'mi';
export const mk = 'mk';
export const ml = 'ml';
export const mn = 'mn';
export const mr = 'mr';
export const msMy = 'ms-my';
export const ms = 'ms';
export const mt = 'mt';
export const my = 'my';
export const nb = 'nb';
export const ne = 'ne';
export const nlBe = 'nl-be';
export const nl = 'nl';
export const nn = 'nn';
export const ocLnc = 'oc-lnc';
export const paIn = 'pa-in';
export const pl = 'pl';
export const ptBr = 'pt-br';
export const pt = 'pt';
export const rn = 'rn';
export const ro = 'ro';
export const ru = 'ru';
export const rw = 'rw';
export const sd = 'sd';
export const se = 'se';
export const si = 'si';
export const sk = 'sk';
export const sl = 'sl';
export const sq = 'sq';
export const srCyrl = 'sr-cyrl';
export const sr = 'sr';
export const ss = 'ss';
export const svFi = 'sv-fi';
export const sv = 'sv';
export const sw = 'sw';
export const ta = 'ta';
export const te = 'te';
export const tet = 'tet';
export const tg = 'tg';
export const th = 'th';
export const tk = 'tk';
export const tlPh = 'tl-ph';
export const tlh = 'tlh';
export const tr = 'tr';
export const tzl = 'tzl';
export const tzmLatn = 'tzm-latn';
export const tzm = 'tzm';
export const ugCn = 'ug-cn';
export const uk = 'uk';
export const ur = 'ur';
export const uzLatn = 'uz-latn';
export const uz = 'uz';
export const vi = 'vi';
export const xPseudo = 'x-pseudo';
export const yo = 'yo';
export const zhCn = 'zh-cn';
export const zhHk = 'zh-hk';
export const zhTw = 'zh-tw';
export const zh = 'zh';
export const esMx = 'es-mx';
export const esPr = 'es-pr';
export const esUs = 'es-us';
