import { Event } from '@sentry/types';

function beforeSend(event: Event): Event | null {
    if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
    ) {
        const e = event.exception.values[0];
        if (
            e.type === 'UnhandledRejection' &&
            e.value === 'Non-Error promise rejection captured with value: Could not clear consent from root domain.'
        ) {
            return null;
        }
    }
    return event;
}

export default beforeSend;
