import { LanguageRoute } from '@models/languageRoute';
import { UserRole } from '@models/authentication';

export enum EntityName {
    Page = 'page',
    BlogArticle = 'blogArticle',
    PressReleaseArticle = 'pressReleaseArticle',
    WhitePaperArticle = 'whitePaperArticle',
    SuccessStoryArticle = 'successStoryArticle',
    GlossaryArticle = 'glossaryArticle',
    Author = 'author',
    Category = 'category',
    Tag = 'tag',
    Video = 'video',
    Document = 'document',
}

export enum EntityId {
    Page = 0,
    BlogArticle = 1,
    PressReleaseArticle = 2,
    WhitePaperArticle = 3,
    SuccessStoryArticle = 4,
    Author = 5,
    Category = 6,
    Tag = 7,
    Video = 8,
    Document = 9,
    GlossaryArticle = 10,
}

export interface EntityType {
    id: EntityId;
    name: EntityName;
    routeKey: keyof LanguageRoute | null;
    getBy: string;
    apiName: string;
    friendlyName: string;
    adminEditUrl?: string;
    allowedRoles: UserRole[];
    homeRouteKey?: keyof LanguageRoute;
    translatedHomeName?: string;
    translatedName: string;
}
