import { breakpoints } from '@web-for-marketing/react-ui';

export const pageNavPadding = {
    rootNavPadding: {
        marginRight: 0,
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            display: 'block !important',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            paddingTop: '0 !important',
        },
    },
    noPadding: {
        paddingTop: '0 !important',
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            display: 'block !important',
        },
    },
} as const;
