import { Validator, ValidatorMessage } from '@web-for-marketing/react-ui';
import { hasValue, isLengthValid, HasLengthPayload } from '@helpers/operand';
import dayjs from 'dayjs';
import { FormComponent } from '@models/customForm';

export function getValidatorMessage(message: string, isWarning?: boolean): ValidatorMessage {
    return isWarning ? { message: message, isWarning: true } : message;
}

export function isNotEmpty(message: string, isWarning?: boolean): Validator {
    return (value) => (hasValue(value) ? null : getValidatorMessage(message, isWarning));
}

export function hasLength(length: HasLengthPayload, message: string, isWarning?: boolean): Validator {
    return (value) => (isLengthValid(length, value) ? null : getValidatorMessage(message, isWarning));
}

export function hasDuplicateFieldID(message: string, isWarning?: boolean): Validator {
    return (value) => {
        const currentID = (value as FormComponent).fieldId;
        const hasDuplicate =
            (value as FormComponent).conditionalFields?.some((obj) =>
                obj.components.some((subcomponent) => subcomponent.fieldId === currentID)
            ) || false;

        return hasDuplicate ? getValidatorMessage(message, isWarning) : null;
    };
}

export function notEmptyAndHasLength(length: HasLengthPayload, message: string, isWarning?: boolean): Validator {
    return (value) => (!value || isLengthValid(length, value) ? null : getValidatorMessage(message, isWarning));
}

export function isNotEqual(field: string, message: string, isWarning?: boolean): Validator {
    const validatorMessage = getValidatorMessage(message, isWarning);

    return (value, values) => {
        if (values) {
            const fieldValue = values[field];

            if (typeof value === 'string' && typeof fieldValue === 'string') {
                return value.trim().toLowerCase() === fieldValue.trim().toLowerCase() ? validatorMessage : null;
            }

            return value === fieldValue ? validatorMessage : null;
        }

        return null;
    };
}

export function regexMatches(pattern: RegExp, message: string, isWarning?: boolean): Validator {
    const validatorMessage = getValidatorMessage(message, isWarning);

    return (value) => (typeof value === 'string' && value.match(pattern) ? null : validatorMessage);
}

export function regexMatchesOrIsEmpty(pattern: RegExp, message: string, isWarning?: boolean): Validator {
    const validatorMessage = getValidatorMessage(message, isWarning);

    return (value) =>
        typeof value === 'string' && (value.match(pattern) || value.length === 0) ? null : validatorMessage;
}

export const hasValidDateString = (message: string, isWarning?: boolean): Validator => {
    const validatorMessage = getValidatorMessage(message, isWarning);

    function isValidDateString(value: string): boolean {
        return dayjs(value).isValid();
    }

    return (value) =>
        typeof value !== 'string' || (typeof value === 'string' && !isValidDateString(value)) ? validatorMessage : null;
};

export const hasValidJsonString = (message: string, isWarning?: boolean): Validator => {
    const validatorMessage = getValidatorMessage(message, isWarning);

    function isValidJsonString(value: string): boolean {
        const regex = /^\{.*\}$/;
        if (!regex.test(value)) {
            return false;
        }
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (value) =>
        typeof value !== 'string' || (typeof value === 'string' && !isValidJsonString(value)) ? validatorMessage : null;
};

export const hasValidXMLString = (message: string, isWarning?: boolean): Validator => {
    const validatorMessage = getValidatorMessage(message, isWarning);

    function isValidXmlString(value: string): boolean {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(value, 'text/xml');
        const errorNode = xmlDoc.querySelector('parsererror');

        if (errorNode) {
            return false;
        }

        return true;
    }

    return (value) =>
        typeof value !== 'string' || (typeof value === 'string' && !isValidXmlString(value)) ? validatorMessage : null;
};
