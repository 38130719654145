import React from 'react';
import { v2Colors } from '@web-for-marketing/react-ui';

export function NavErrorIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            color={v2Colors.core.ember}
            {...props}
        >
            <path
                d='M19.4978 9.82938V9.82945C19.5112 15.1118 15.2734 19.4223 9.99167 19.4989L9.99141 19.4989C7.4915 19.5366 5.08064 18.5715 3.29724 16.8192C1.51384 15.0669 0.506446 12.6734 0.500039 10.1732L0.500038 10.1731C0.485282 4.88981 4.72341 0.577709 10.0062 0.50105L10.0064 0.501047C12.5067 0.463662 14.9178 1.42919 16.7013 3.18198C18.4847 4.93477 19.4919 7.32881 19.4978 9.82938Z'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
                fill='currentColor'
            />
            <path
                d='M10.3536 11.1046C10.2598 11.0108 10.1326 10.9581 10 10.9581C9.86741 10.9581 9.74023 11.0108 9.64646 11.1046L6.88141 13.8696C6.79417 13.9569 6.65273 13.9569 6.56549 13.8696C6.47826 13.7824 6.47826 13.6409 6.56549 13.5537L9.33055 10.7886C9.52581 10.5934 9.52581 10.2768 9.33055 10.0815L6.56543 7.31641C6.47819 7.22917 6.47819 7.08773 6.56543 7.00049L6.21731 6.65237L6.56543 7.00049C6.65267 6.91325 6.79411 6.91325 6.88134 7.00049L7.2349 6.64693L6.88134 7.00049L9.64646 9.76562C9.74023 9.85939 9.86741 9.91206 10 9.91206C10.1326 9.91206 10.2598 9.85939 10.3536 9.76562L13.1187 7.00052C13.2059 6.91328 13.3473 6.91328 13.4346 7.00052L13.7881 6.64697L13.4346 7.00052C13.5218 7.08776 13.5218 7.2292 13.4346 7.31644L13.7881 7.66999L13.4346 7.31644L10.6695 10.0815C10.4742 10.2768 10.4742 10.5934 10.6695 10.7886L13.4345 13.5537C13.5217 13.6409 13.5217 13.7824 13.4345 13.8696C13.3473 13.9568 13.2058 13.9568 13.1186 13.8696L10.3536 11.1046Z'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
