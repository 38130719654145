import React from 'react';

export function ChatBubble(): JSX.Element {
    return (
        <svg
            aria-hidden
            id='chatBubble'
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='14'
            viewBox='0 0 800 800'
            fill='#FFF'
        >
            <path d='M524.8,59.4h-249.5C123.1,59.4,0,182.7,0,334.7s78.9,224.6,188.3,261v131.2c0,12.3,14.6,18.3,23.3,9.7l126.6-126.6h186.7c151.9,0,275.2-123.3,275.2-275.2S676.9,59.4,524.8,59.4Z"/'></path>
        </svg>
    );
}
