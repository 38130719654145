import { UserRole } from '@models/authentication';

export const permissions = {
    basic: 'Basic admin permission',
    pages: 'Pages permission',
    articles: 'Articles permission',
    publish: 'Publish permission',
    navigation: 'Navigation permission',
    media: 'Media gallery permission',
    files: 'File gallery permission',
    videoGallery: 'Video gallery permission',
    serverInfo: 'Server info permission',
    siteSetting: 'Site setting permission',
    documents: 'Documents permission',
    hrefLang: 'Hreflang permission',
    dashboard: 'Dashboard permission',
    tools: 'Tools permission',
    uploads: 'Uploads permission',
    help: 'Help permission',
};

export const accessLevels = {
    none: 0,
    view: 1, //_R__
    edit: 2, //CRU_
    full: 3, //CRUD
};

export const rolePermissions = {
    [UserRole.GeotabComCmsAdmin]: {
        [permissions.basic]: accessLevels.view,
        [permissions.pages]: accessLevels.full,
        [permissions.videoGallery]: accessLevels.full,
        [permissions.media]: accessLevels.full,
        [permissions.files]: accessLevels.full,
        [permissions.navigation]: accessLevels.full,
        [permissions.serverInfo]: accessLevels.full,
        [permissions.siteSetting]: accessLevels.full,
        [permissions.articles]: accessLevels.full,
        [permissions.publish]: accessLevels.full,
        [permissions.documents]: accessLevels.full,
        [permissions.hrefLang]: accessLevels.full,
        [permissions.dashboard]: accessLevels.view,
        [permissions.tools]: accessLevels.full,
        [permissions.uploads]: accessLevels.full,
        [permissions.help]: accessLevels.full,
    },

    [UserRole.GeotabComArticleAdmin]: {
        [permissions.basic]: accessLevels.view,
        [permissions.pages]: accessLevels.none,
        [permissions.videoGallery]: accessLevels.none,
        [permissions.media]: accessLevels.edit,
        [permissions.files]: accessLevels.edit,
        [permissions.navigation]: accessLevels.none,
        [permissions.serverInfo]: accessLevels.none,
        [permissions.siteSetting]: accessLevels.none,
        [permissions.articles]: accessLevels.full,
        [permissions.publish]: accessLevels.full,
        [permissions.documents]: accessLevels.none,
        [permissions.hrefLang]: accessLevels.edit,
        [permissions.dashboard]: accessLevels.view,
        [permissions.tools]: accessLevels.full,
        [permissions.uploads]: accessLevels.full,
        [permissions.help]: accessLevels.full,
    },

    [UserRole.GeotabComArticleEditor]: {
        [permissions.basic]: accessLevels.view,
        [permissions.pages]: accessLevels.none,
        [permissions.videoGallery]: accessLevels.none,
        [permissions.media]: accessLevels.edit,
        [permissions.files]: accessLevels.edit,
        [permissions.navigation]: accessLevels.none,
        [permissions.serverInfo]: accessLevels.none,
        [permissions.siteSetting]: accessLevels.none,
        [permissions.articles]: accessLevels.full,
        [permissions.publish]: accessLevels.none,
        [permissions.documents]: accessLevels.none,
        [permissions.hrefLang]: accessLevels.edit,
        [permissions.dashboard]: accessLevels.view,
        [permissions.tools]: accessLevels.full,
        [permissions.uploads]: accessLevels.full,
        [permissions.help]: accessLevels.full,
    },
};
