import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { isNavbarDisabledAtom } from '@atoms/navigation';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import { Breadcrumbs as MUIBreadcrumb } from '@mui/material';
import { breakpoints, getRgbaColor, v2Colors } from '@web-for-marketing/react-ui';
import { Text } from '@web-for-marketing/react-ui';
import { RightArrowIcon } from '@icon/RightArrowIcon';
import { getLanguageUrlPrefixByCode } from '@helpers/languages';
import { selectedLanguageAtom } from '@atoms/appSettings';

const classes = {
    breadcrumb: {
        display: 'flex',
        height: '3.6rem',
        padding: '0 3.6rem',
        backgroundColor: v2Colors.background.light,
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: getRgbaColor('#000000', 0.02),
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& .MuiBreadcrumbs-ol': {
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            } as const,
        },
    },
    breadcrumbLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
};

export function PageBreadcrumb(): JSX.Element | null {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const [isNavbarDisabled] = useAtom(isNavbarDisabledAtom);
    const { selectedLanguageTranslation } = useTranslation();
    const breadcrumbTranslation = useMemo(
        () => getTranslation(selectedLanguageTranslation, 'breadcrumb'),
        [selectedLanguageTranslation]
    );
    const { breadcrumbs, isPageBreadcrumbDisabled, isRegionBreadcrumbDisabled } = useStaticValues();
    const showBreadcrumb =
        !isRegionBreadcrumbDisabled && !isPageBreadcrumbDisabled && !isNavbarDisabled && breadcrumbs.length > 0;

    return showBreadcrumb ? (
        <MUIBreadcrumb
            separator={<RightArrowIcon color={v2Colors.text.secondary} />}
            aria-label='breadcrumb'
            css={classes.breadcrumb}
        >
            <Text
                component='a'
                href={getLanguageUrlPrefixByCode(selectedLanguage)}
                variant='body2'
                css={classes.breadcrumbLink}
            >
                {breadcrumbTranslation.home}
            </Text>
            {breadcrumbs.map((breadcrumb, index) => (
                <Text
                    key={index}
                    component='a'
                    href={breadcrumb.url}
                    variant='body2'
                    color={index === breadcrumbs.length - 1 ? 'activeSecondary' : 'secondary'}
                    css={classes.breadcrumbLink}
                >
                    {breadcrumb.displayName}
                </Text>
            ))}
        </MUIBreadcrumb>
    ) : null;
}
