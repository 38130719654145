import { CookieKey } from '@models/cookie';

export function setCookie(key: CookieKey, value: string | number | boolean, daysToExpire = 30): void {
    const secondsInADay = 86400;
    const secondsToExpire = daysToExpire * secondsInADay;

    try {
        document.cookie = `${key}=${value}; max-age=${secondsToExpire}; path=/`;
    } catch (error) {
        console.error(error);
    }
}

export function deleteCookie(key: CookieKey): void {
    document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function getCookie(key: CookieKey): string {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
