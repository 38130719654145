import React from 'react';
import { Grid } from '@mui/material';
import { footerStyles as classes } from './styleObjects/Footer';
import { ensureInternalURLTrailingSlash } from '@helpers/string';
import { CMSLink } from '@components/CMSLink';
import { NewTabIcon } from '@icon/NewTabIcon';
import { LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { FooterTopContent } from '@models/footer';
import { useStaticValues } from '@stateManagement/StaticValuesContext';

interface TopFooterSectionProps {
    sectionData: FooterTopContent[];
}

export function TopFooterSection({ sectionData }: TopFooterSectionProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();

    const renderTopFooterSection = (footerContent: FooterTopContent): JSX.Element | undefined => {
        switch (footerContent.type) {
            case 'sectionLink':
                return (
                    <>
                        <Text variant='body1' component='h2' color='inversePrimary' css={classes.topFooterSpacing}>
                            {footerContent.title}
                        </Text>
                        <div>
                            {footerContent.links && footerContent.links.length
                                ? footerContent.links.map((content, index) => (
                                    <CMSLink
                                        key={index}
                                        type='link'
                                        variant='body2'
                                        href={ensureInternalURLTrailingSlash(content.href)}
                                        target={content.isTargetToNewTab ? '_blank' : undefined}
                                        underline='none'
                                        css={[classes.textLink, classes.bottomDivider]}
                                    >
                                        {content.title}{' '}
                                        {content.isTargetToNewTab ? <NewTabIcon aria-hidden='true' /> : null}
                                    </CMSLink>
                                ))
                                : null}
                        </div>
                    </>
                );
            case 'imageLink':
                return (
                    <LazyLoadContainer component={Grid} container css={classes.topFooterSpacing}>
                        <CMSLink
                            href={footerContent.href}
                            aria-label={footerContent.title}
                            target={footerContent.isTargetToNewTab}
                        >
                            <img
                                src=''
                                data-src={prefixStringWithBaseUrl(footerContent.imagePath)}
                                alt={footerContent.imageAlt}
                                css={classes.image}
                            />
                        </CMSLink>
                    </LazyLoadContainer>
                );
            default:
                break;
        }
    };

    return (
        <>
            {sectionData.length ? (
                <Grid item xs={12} md={3} css={classes.linkSection} data-testid='TopFooterSectionTestID'>
                    <Grid container direction='column'>
                        {sectionData.map((footerContent, index) => (
                            <div key={index}>{renderTopFooterSection(footerContent)}</div>
                        ))}
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
}
