import { hasPermission } from '@services/authorizationService';
import { useAtom } from 'jotai';
import { isAuthenticatedAtom, userRolesAtom } from '@atoms/authentication';

export function useAdminRoute(permission: string, minimumAccessLevel: number): boolean {
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);
    const [userRoles] = useAtom(userRolesAtom);

    const hasRouteAccess = isAuthenticated && hasPermission(userRoles, permission, minimumAccessLevel);

    return hasRouteAccess;
}
