import React, { useState, useCallback } from 'react';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { LoadingDialog } from '../components/LoadingDialog';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext();

export function NotificationProvider({ children, ...overrides }) {
    const [openSnackbar, setOpenSnackbar] = useState({
        state: false,
        variant: 'success',
        message: '',
        autoHideDuration: 6000,
        subtitleMessage: '',
        ...overrides,
    });
    const [loadingCount, setLoadingCount] = useState(0);

    const show = useCallback(
        (message, variant = 'info', autoHideDuration = 6000, subtitleMessage = '', ...overrides) => {
            setOpenSnackbar({ state: true, variant, message, autoHideDuration, subtitleMessage, ...overrides });
        },
        []
    );

    function showLoading() {
        setLoadingCount((count) => count + 1);
    }

    function hideLoading() {
        setLoadingCount((count) => (count > 0 ? count - 1 : 0));
    }

    function clearLoading() {
        setLoadingCount(0);
    }

    function isLoading() {
        return loadingCount > 0;
    }

    return (
        <NotificationContext.Provider
            value={{
                show,
                open: openSnackbar,
                setOpen: setOpenSnackbar,
                showLoading,
                hideLoading,
                clearLoading,
                isLoading,
            }}
        >
            {children}
            <CustomSnackbar
                message={openSnackbar.message}
                variant={openSnackbar.variant}
                autoHideDuration={openSnackbar.autoHideDuration}
                subtitleMessage={openSnackbar.subtitleMessage}
            />
            <LoadingDialog open={isLoading()} />
        </NotificationContext.Provider>
    );
}

NotificationProvider.propTypes = {
    children: PropTypes.node.isRequired,
    overrides: PropTypes.object,
};
