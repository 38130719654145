// This yields to the main thread by briefly interrupting the task queue to give the browser opportunities to run more important tasks
// It allows the browser to paint the frame, giving the user immediate feedback that their action was accepted.
// https://vercel.com/blog/demystifying-inp-new-tools-and-actionable-insights
// https://web.dev/articles/optimize-long-tasks#async-await
export function interactionResponse(): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, 100); // Fallback for the case where the animation frame never fires.
        requestAnimationFrame(() => {
            setTimeout(resolve, 0);
        });
    });
}
