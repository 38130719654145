import React from 'react';

export function FooterGeotabLogo(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1800 239'
            width='150px'
            height='20px'
            {...props}
        >
            <defs>
                <style>{'.cls-1{fill: #fffFFF;'}</style>
            </defs>
            <g data-name='Layer 2'>
                <path
                    className='cls-1'
                    d='M1795,202.741a29.416,29.416,0,1,1-8.781-20.855A28.362,28.362,0,0,1,1795,202.741Zm-4,0a24.807,24.807,0,0,0-7.464-17.562,25.289,25.289,0,0,0-35.739-.263l-.263.263a25.641,25.641,0,0,0,0,36.178,25.289,25.289,0,0,0,35.739.263l.263-.263a24.807,24.807,0,0,0,7.464-18.44Zm-6.542,15.147h-7.639l-11.24-12.074h-5.488v12.074h-5.752v-31.26h10.625a41.845,41.845,0,0,1,5.225,0,12.163,12.163,0,0,1,4.083,1.317,7.992,7.992,0,0,1,3.117,2.854,7.814,7.814,0,0,1,.966,4.171,8.079,8.079,0,0,1-1.932,5.488,13.172,13.172,0,0,1-5.313,3.468Zm-11.986-22.26a4.787,4.787,0,0,0-.439-2.2,3.645,3.645,0,0,0-1.581-1.581,6.543,6.543,0,0,0-2.2-.7h-8.079v10.537h4.39a17.56,17.56,0,0,0,3.644,0,5.928,5.928,0,0,0,2.5-1.054,4.786,4.786,0,0,0,1.493-1.844,6.632,6.632,0,0,0,.351-3.293Z'
                />
                <polygon
                    className='cls-1'
                    points='327.373 232.163 549.321 232.163 549.321 209.464 367.466 209.464 367.466 127.086 541.794 127.086 541.794 104.328 367.466 104.328 367.466 29.358 549.321 29.358 549.321 6.6 327.373 6.6 327.373 232.163'
                />
                <path
                    className='cls-1'
                    d='M847.928,23.016C831.749,10.63,790.56,4.348,725.812,4.348c-56.006,0-94.824,5.512-115.36,16.387S579.22,52.441,579.22,82.37l-.5,21.306v15.735c0,51.531,8.09,83.771,23.943,96.454s57.4,18.787,123.153,18.787,107.714-6.519,123.153-18.965,23.943-45.96,23.943-99.21C872.582,66.991,864.048,35.521,847.928,23.016Zm-13.809,96.276c0,45.9-4.534,73.074-13.572,80.778s-41.041,11.675-95.15,11.675-85.934-3.941-94.824-11.675S617,165.193,617,119.293s4.682-72.9,13.69-80.6,40.982-11.675,94.824-11.675c46.908,0,77.045,3.348,89.757,9.927S834.119,59.227,834.119,83.5Z'
                />
                <polygon
                    className='cls-1'
                    points='1151.158 6.6 888.228 6.6 888.228 31.491 1000.121 31.491 1000.121 232.163 1040.273 232.163 1040.273 31.491 1151.158 31.491 1151.158 6.6'
                />
                <path
                    className='cls-1'
                    d='M1292.861,6.6h-55.146L1111.184,232.163H1153.8l26.4-47.412h173.084l26.669,47.412h41.9l-128.813-225ZM1192.11,164.275l74.081-134.887,75.03,134.828Z'
                />
                <path
                    className='cls-1'
                    d='M1670.054,113.9c28.121-6.875,42.434-22.787,42.434-47.412s-6.579-40.834-19.824-48.449S1651.741,6.6,1609.693,6.6H1457V232.163H1625.9c35.559,0,60.806-4.919,75.207-14.55s21.78-26.669,21.78-50.672C1723.067,137.843,1705.228,120,1670.054,113.9ZM1497.208,29.358h111.715c27.41,0,45.367,2.222,53.339,6.578s11.853,13.957,11.853,28.714c0,17.068-4.534,28.181-13.572,33.04s-29.929,7.467-62.228,7.467H1497.208Zm174.151,172.521c-8.89,4.919-28.358,7.349-57.635,7.349H1497.208V127.916h99.062c40.626,0,65.814,2.282,74.911,6.756s13.453,16.565,13.453,36.27C1684.693,186.647,1680.218,197.078,1671.358,201.879Z'
                />
                <path
                    className='cls-1'
                    d='M141.1,119.293v22.728H252.284v13.038h0v.83h0c0,25.069-4.978,40.8-14.372,46.671s-35.352,9.1-76.482,9.1c-53.872,0-87.3-3.23-99.625-9.631s-18.283-23.5-18.283-51.264v-32l.385-29.9c0-27.41,5.571-44.775,16.505-51.59S99.617,26.9,144.273,26.9c47.886,0,78.319,2.163,89.757,6.46S250.3,49.034,250.3,66.991v5.927h39.174v-9.1c0-25.01-8.682-41.486-25.84-48.657S208.783,4.348,150.614,4.348,53.42,9.889,34.218,20.853,5,54.368,5,87.645v52.894C5,180.158,13.89,206,31.225,217.376s56.657,17.187,117.049,17.187c63.917,0,104.01-4.86,119.212-14.49s22.017-32.062,22.965-68.155h0v-32.6Z'
                />
            </g>
        </svg>
    );
}
