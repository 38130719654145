export enum LanguageCode {
    en = 'en-us',
    enGb = 'en-gb',
    enAu = 'en-au',
    apac = 'en-sg',
    ie = 'en-ie',
    frCa = 'fr-ca',
    frFr = 'fr-fr',
    es = 'es-es',
    esMx = 'es-mx',
    de = 'de-de',
    it = 'it-it',
    ptBr = 'pt-br',
    pl = 'pl-pl',
    nl = 'nl-nl',
    se = 'sv-se',
    ae = 'en-ae',
    enCb = 'en-cb',
    idn = 'id-id',
}
