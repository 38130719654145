import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { breakpoints } from '@web-for-marketing/react-ui';

interface BaseProps {
    children: React.ReactNode;
    containerReset?: boolean;
    childSpacing?: boolean;
}

type GridContainerProps<C extends React.ElementType> = BaseProps & GridProps<C, { component?: C }>;

const classes = {
    geotabGrid: {
        boxSizing: 'border-box',
        padding: '8rem 0',
        maxWidth: 1630,
        margin: '0 auto',
        '@media (max-width: 1679.9px)': {
            paddingLeft: '3.6rem ',
            paddingRight: '3.6rem ',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '4rem 3.6rem ',
            overflowX: 'hidden',
        },
    },
    childSpacing: {
        '& > *': {
            padding: '0 2%',
            boxSizing: 'border-box',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '& > *': {
                padding: '0 !important',
                width: '100%',
            },
        },
    },
} as const;

/**
 * @deprecated Use the Container component instead.
 */
export function GridContainer<C extends React.ElementType>({
    children,
    containerReset,
    childSpacing,
    ...other
}: GridContainerProps<C>): JSX.Element {
    return (
        <Grid
            data-testid='gridContainer'
            container
            css={[childSpacing ? classes.childSpacing : '', !containerReset ? classes.geotabGrid : '']}
            {...other}
        >
            {children}
        </Grid>
    );
}

GridContainer.defaultProps = {
    className: '',
    containerReset: false,
    childSpacing: true,
};
