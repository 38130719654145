//import './wdyr';
import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { App } from './App';
import * as Sentry from '@sentry/react';
import beforeSend from '@helpers/sentryHelper';
import { isAdminRoute } from '@helpers/routeHelpers';
import { initializeGeotabSourceCookie } from '@helpers/geotabSourceCookieHelper';

const windowState = window.__INITIAL_STATE__;
const rootDiv = document.getElementById('root');

const sentrySettings = windowState.sentrySettings;
if (sentrySettings.enabled) {
    Sentry.init({
        beforeSend,
        ...sentrySettings,
        integrations: [Sentry.browserTracingIntegration()],
        ignoreErrors: [
            /^ResizeObserver loop completed with undelivered notifications/,
            /^ResizeObserver loop limit exceeded/,
            /^Non-Error promise rejection captured with value: Object Not Found/,
        ],
    });
    Sentry.setTag('platform', 'react');
}

if (!isAdminRoute(window.location.pathname)) {
    initializeGeotabSourceCookie();
}

const app = (
    <App
        initialData={windowState.initialData}
        baseUrl={windowState.baseUrl}
        fullUrl={decodeURIComponent(windowState.fullUrl)}
        isMobile={windowState.isMobile}
        myAdminBaseApiUrl={windowState.myAdminBaseApiUrl}
        authenticationSettings={windowState.authenticationSettings}
        myAdminUser={windowState.myAdminUser}
        mediaUrlPrefix={windowState.mediaUrlPrefix}
        generalFileUrlPrefix={windowState.generalFileUrlPrefix}
    />
);

if (windowState.clientSideRender) {
    const root = createRoot(rootDiv);
    root.render(app);
} else {
    loadableReady(() => {
        ReactDOMClient.hydrateRoot(rootDiv, app);
    }).catch((error) => {
        console.error('Error occurred executing loadableReady', error);
    });
}
