import React from 'react';
import Helmet from 'react-helmet';

type PageHelmetPreloadLinkProps = {
    href: string;
    as: 'font' | 'image' | 'script' | 'style' | 'track';
};

export function PageHelmetPreloadLink({ href, as }: PageHelmetPreloadLinkProps): JSX.Element {
    return (
        <Helmet>
            <link rel='preload' as={as} href={href} />
        </Helmet>
    );
}
