import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { accessLevels, permissions } from '@helpers/authorization';
import { PublicLayout } from './PublicLayout';
import { LanguageRoutes } from './LanguageRoutes';
import { AdminRouting } from './AdminRouting';
import { useAdminRoute } from '@hooks/useAdminRoute';

// Public
const AdminLoginLoadable = loadable(() => import('@pages/public/AdminLogin'));
const SubscribePageLoadable = loadable(() => import('@pages/public/SubscribePage'));
const PageSlugLoadable = loadable(() => import('@pages/public/PageSlug'));
const FrequentlyAskedQuestionsLoadable = loadable(() => import('@pages/public/FrequentlyAskedQuestions'));
const PagePreviewLoadable = loadable(() => import('@pages/public/PagePreview'));

export function Routing(): JSX.Element {
    const hasAdminAccess = useAdminRoute(permissions.basic, accessLevels.view);

    return (
        <Routes>
            {hasAdminAccess ? AdminRouting() : null}
            <Route element={<PublicLayout />}>
                <Route path='/faq/' element={<FrequentlyAskedQuestionsLoadable />} />
                <Route path='/subscribe/' element={<SubscribePageLoadable />} />
                <Route path='/login/' element={<AdminLoginLoadable />} />
                <Route path='/preview/:id' element={<PagePreviewLoadable />} />
                {LanguageRoutes()}
                <Route path='*' element={<PageSlugLoadable />} />
            </Route>
        </Routes>
    );
}
