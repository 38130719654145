import { LanguageRoute } from '@models/languageRoute';

export const languageRoutes: Record<string, LanguageRoute> = {
    en: {
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        pressRoomHomeUrl: 'press-releases/',
        pressReleaseUrl: 'press-release/',
        successStoriesHomeUrl: 'success-stories/',
        caseStudyUrl: 'case-study/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        glossaryUrl: 'glossary/',
        becomeInstallerUrl: 'become-installer/',
        videoUrl: 'video/',
        documentationUrl: 'documentation/',
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'search/',
    },
    enUk: {
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        pressRoomHomeUrl: 'press-room/',
        pressReleaseUrl: 'press-release/',
        successStoriesHomeUrl: 'success-stories/',
        caseStudyUrl: 'case-study/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        glossaryUrl: 'glossary/',
        myGeotabLoginUrl: 'mygeotab-login/',
        becomeInstallerUrl: 'become-installer/',
        videoUrl: 'video/',
        documentationUrl: 'documentation/',
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'search/',
    },
    frCa: {
        thankYouUrl: 'merci/',
        thankYouBlogUrl: 'merci-blog/',
        becomeInstallerUrl: 'devenez-installateur/',
        myGeotabLoginUrl: 'mygeotab-login/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'chercher/',
    },
    es: {
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/autor/',
        categoryUrl: 'blog/categoria/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        pressRoomHomeUrl: 'sala-de-prensa/',
        pressReleaseUrl: 'nota-de-prensa/',
        successStoriesHomeUrl: 'casos-de-exito/',
        caseStudyUrl: 'casos-de-exito/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        myGeotabLoginUrl: 'mygeotab-login/',
        becomeInstallerUrl: 'hágase-instalador/',
        documentationUrl: 'documentation/',
        thankYouUrl: 'gracias/',
        thankYouBlogUrl: 'gracias-blog/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'buscar/',
    },
    de: {
        pressRoomHomeUrl: 'pressebereich/',
        myGeotabLoginUrl: 'mygeotab-login/',
        whitePaperUrl: 'white-paper/',
        whitePapersHomeUrl: 'white-papers/',
        successStoriesHomeUrl: 'erfolgsgeschichten/',
        caseStudyUrl: 'case-study/',
        pressReleaseUrl: 'presse/',
        categoryUrl: 'blog/kategorie/',
        authorUrl: 'blog/autor/',
        blogPostUrl: 'blog/',
        blogHomeUrl: 'blog/',
        becomeInstallerUrl: 'monteur-werden/',
        thankYouUrl: 'danke/',
        thankYouBlogUrl: 'danke-blog/',
        tagUrl: 'etikett/',
        documentationUrl: 'dokumentation/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'suche/',
    },
    it: {
        thankYouUrl: 'grazie/',
        thankYouBlogUrl: 'grazie-blog/',
        becomeInstallerUrl: 'diventa-installatore/',
        myGeotabLoginUrl: 'mygeotab-accedi/',
        authorUrl: 'blog/autore/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        successStoriesHomeUrl: 'storie-di-successo/',
        caseStudyUrl: 'caso-di-studio/',
        pressRoomHomeUrl: 'area-stampa/',
        pressReleaseUrl: 'comunicati-stampa/',
        blogHomeUrl: 'blog/',
        categoryUrl: 'blog/categoria/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'cerca/',
    },
    frFr: {
        thankYouUrl: 'merci/',
        thankYouBlogUrl: 'merci-blog/',
        whitePaperUrl: 'livre-blanc/',
        whitePapersHomeUrl: 'livres-blancs/',
        pressRoomHomeUrl: 'salle-de-presse/',
        pressReleaseUrl: 'espace-presse/',
        successStoriesHomeUrl: 'temoignages-reussites/',
        caseStudyUrl: 'case-study/',
        becomeInstallerUrl: 'devenir-installateur/',
        myGeotabLoginUrl: 'mygeotab-login/',
        authorUrl: 'blog/auteur/',
        categoryUrl: 'blog/categorie/',
        blogHomeUrl: 'blog/',
        blogPostUrl: 'blog/',
        tagUrl: 'marque/',
        sitemap: 'sitemap/',
        documentationUrl: 'documentation/',
        publicSearchUrl: 'chercher/',
    },
    ptBr: {
        thankYouUrl: 'obrigado/',
        thankYouBlogUrl: 'obrigado-blog/',
        publicSearchUrl: 'buscar/',
        pressRoomHomeUrl: 'sala-de-imprensa/',
        pressReleaseUrl: 'sala-de-imprensa/',
        becomeInstallerUrl: 'torne-se-um-instalador/',
        myGeotabLoginUrl: 'mygeotab-login/',
        whitePaperUrl: 'documento-tecnico/',
        whitePapersHomeUrl: 'white-papers/',
        successStoriesHomeUrl: 'historias-de-sucesso/',
        authorUrl: 'blog/autor/',
        caseStudyUrl: 'estudo-de-caso/',
        categoryUrl: 'blog/categoria/',
        blogHomeUrl: 'blog/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
    },
    enAu: {
        pressRoomHomeUrl: 'press-room/',
        pressReleaseUrl: 'press-release/',
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        videoUrl: 'video/',
        successStoriesHomeUrl: 'success-stories/',
        caseStudyUrl: 'case-study/',
        whitePaperUrl: 'white-paper/',
        whitePapersHomeUrl: 'white-papers/',
        glossaryUrl: 'glossary/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogHomeUrl: 'blog/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        sitemap: 'sitemap/',
        documentationUrl: 'documentation/',
        publicSearchUrl: 'search/',
    },
    esMx: {
        thankYouUrl: 'gracias/',
        thankYouBlogUrl: 'gracias-blog/',
        authorUrl: 'blog/autor/',
        categoryUrl: 'blog/categoria/',
        blogPostUrl: 'blog/',
        blogHomeUrl: 'blog/',
        pressRoomHomeUrl: 'sala-de-prensa-y-noticias/',
        pressReleaseUrl: 'comunicado-de-prensa/',
        tagUrl: 'tag/',
        videoUrl: 'video/',
        myGeotabLoginUrl: 'mygeotab-login/',
        successStoriesHomeUrl: 'casos-de-exito/',
        caseStudyUrl: 'casos-de-exito/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        sitemap: 'sitemap/',
        publicSearchUrl: 'buscar/',
    },
    pl: {
        thankYouUrl: 'Dziękuję-Ci/',
        publicSearchUrl: 'szukaj/',
    },
    nl: {
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        thankYouUrl: 'bedankt/',
        thankYouBlogUrl: 'thank-you-blog/',
        publicSearchUrl: 'zoek/',
        myGeotabLoginUrl: 'mygeotab-login/',
        pressRoomHomeUrl: 'perskamer/',
        pressReleaseUrl: 'persbericht/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        successStoriesHomeUrl: 'succes-verhalen/',
        caseStudyUrl: 'case-study/',
        becomeInstallerUrl: 'word-installateur/',
        tagUrl: 'tag/',
        sitemap: 'sitemap/',
        videoUrl: 'video/',
    },
    se: {
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        publicSearchUrl: 'search/',
    },
    ae: {
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        publicSearchUrl: 'search/',
    },
    ie: {
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        publicSearchUrl: 'search/',
        pressRoomHomeUrl: 'press-room/',
        pressReleaseUrl: 'press-release/',
        successStoriesHomeUrl: 'success-stories/',
        caseStudyUrl: 'case-study/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        glossaryUrl: 'glossary/',
        tagUrl: 'tag/',
    },
    apac: {
        thankYouUrl: 'thank-you/',
        thankYouBlogUrl: 'thank-you-blog/',
        publicSearchUrl: 'search/',
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        whitePapersHomeUrl: 'white-papers/',
        whitePaperUrl: 'white-paper/',
        pressRoomHomeUrl: 'press-room/',
        pressReleaseUrl: 'press-release/',
        successStoriesHomeUrl: 'success-stories/',
        caseStudyUrl: 'case-study/',
        tagUrl: 'tag/',
        sitemap: 'sitemap/',
    },
    enCb: {
        thankYouUrl: 'thank-you/',
        publicSearchUrl: 'search/',
    },
    idn: {
        thankYouUrl: 'thank-you/',
        publicSearchUrl: 'search/',
        blogHomeUrl: 'blog/',
        authorUrl: 'blog/author/',
        categoryUrl: 'blog/category/',
        blogPostUrl: 'blog/',
        tagUrl: 'tag/',
        pressRoomHomeUrl: 'press-releases/',
        pressReleaseUrl: 'press-release/',
    },
};

interface LanguageRouteTypes {
    [key: string]: keyof LanguageRoute;
}

export const languageRouteTypes = {
    BLOG_HOME: 'blogHomeUrl',
    BLOG_POST: 'blogPostUrl',
    AUTHOR: 'authorUrl',
    CATEGORY: 'categoryUrl',
    TAG: 'tagUrl',
    WHITE_PAPERS_HOME: 'whitePapersHomeUrl',
    WHITE_PAPER: 'whitePaperUrl',
    SUCCESS_STORIES_HOME: 'successStoriesHomeUrl',
    CASE_STUDY: 'caseStudyUrl',
    PRESS_ROOM_HOME: 'pressRoomHomeUrl',
    PRESS_RELEASE: 'pressReleaseUrl',
    GLOSSARY: 'glossaryUrl',
    MY_GEOTAB_LOGIN: 'myGeotabLoginUrl',
    VIDEO: 'videoUrl',
    DOCUMENT: 'documentationUrl',
} satisfies LanguageRouteTypes;

export const articleTypesArray = Object.values(languageRouteTypes).filter((routeValue) => {
    switch (routeValue) {
        case 'blogPostUrl':
        case 'pressReleaseUrl':
        case 'whitePaperUrl':
        case 'caseStudyUrl':
        case 'glossaryUrl':
            return true;
        default:
            return false;
    }
});
