export const supportedVideoExtensions = ['mp4'];
export const supportedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];
export const mediaUrlPrefixToken = '{{mediaUrlPrefix}}';
export const generalFileUrlPrefixToken = '{{generalFileUrlPrefix}}';

export function getFullMediaPath(mediaUrlPrefix, filePath, fileName) {
    return `${mediaUrlPrefix}${filePath}${fileName}`;
}

export function getParentFolderFromPath(currentPath) {
    if (currentPath === '/') {
        return '/';
    }

    let pathParts = currentPath.split('/');
    pathParts = pathParts.slice(0, pathParts.length - 2);
    return `${pathParts.join('/')}/`;
}

export function resolveImagePath(mediaUrlPrefix, storedImagePath) {
    const regex = new RegExp(`${mediaUrlPrefixToken}`, 'gi');
    return (storedImagePath && storedImagePath.replace(regex, mediaUrlPrefix)) || '';
}

export function resolveGeneralFilePath(generalFileUrlPrefix, storedFilePath) {
    const regex = new RegExp(`${generalFileUrlPrefixToken}`, 'gi');
    return (storedFilePath && storedFilePath.replace(regex, generalFileUrlPrefix)) || '';
}

export function stripMediaUrlPrefixReplacementToken(mediaPath) {
    const regex = new RegExp(`${mediaUrlPrefixToken}`, 'gi');
    return (mediaPath && mediaPath.replace(regex, '')) || '';
}

export function containsMediaUrlPrefixReplacementToken(mediaPath) {
    return (mediaPath && mediaPath.includes(mediaUrlPrefixToken)) || '';
}

export function ensureFilePathIsTrimmed(filePath, maxLength = 35) {
    const lastPeriod = filePath.lastIndexOf('.');
    const trimmedFilePath = lastPeriod !== -1 ? filePath.slice(0, lastPeriod) : filePath;
    return filePath.length > maxLength
        ? `${trimmedFilePath.slice(0, maxLength)}... ${filePath.slice(lastPeriod, filePath.length)}`
        : filePath;
}

export const mediaTypes = {
    video: 'video',
    image: 'image',
};

export function getMediaTypeByPath(filename) {
    const fileExtension = getFileExtension(filename);
    if (supportedVideoExtensions.includes(fileExtension)) {
        return mediaTypes.video;
    } else {
        return mediaTypes.image;
    }
}

export function getFileExtension(filename) {
    return filename.split('.').pop();
}
