import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ensureGeotabTitle, removeUrlParameters } from '@helpers/string';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { getLanguageRouteAndPrefixByCodeAndRouteKey } from '@helpers/languages';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { getLocaleByCode, getLanguageById } from '@helpers/languages';
import geotabShare from '@images/geotabShareImage.jpg';

const supportedImageFormats = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'webp'];

function createAlternateLinks(items, baseUrl) {
    const links = [];

    items.forEach((item) => {
        const langCode = getLanguageById(item.language).code;
        const url = item.url || `${baseUrl}${item.relativeUrl || ''}`;
        links.push(<link key={`${item.entityId}-${langCode}`} rel='alternate' hrefLang={langCode} href={url} />);

        if (item.isDefault) {
            links.push(<link key='x-default' rel='alternate' hrefLang='x-default' href={url} />);
        }
    });

    return links;
}

export function PageHelmet({ seo, noIndex = false, isBlogPage }) {
    const { baseUrl, fullUrl, hrefLangGroup } = useStaticValues();
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const baseUrlWithoutSlash = baseUrl.replace(/\/$/, '');
    const searchUrl = `${baseUrlWithoutSlash}${getLanguageRouteAndPrefixByCodeAndRouteKey(
        selectedLanguage,
        'publicSearchUrl'
    )}`;
    const locale = getLocaleByCode(selectedLanguage);
    const alternateLinks = createAlternateLinks(hrefLangGroup?.items || [], baseUrl);

    let seoDefaults = {
        title: 'Fleet Tracking and Management | Geotab',
        description:
            'Geotab\'s fleet management software and GPS vehicle tracking devices are used to track thousands of vehicles. Get a free demo of our GPS fleet tracking solution!',
        imageUrl: baseUrl + geotabShare,
        canonical: fullUrl,
    };

    if (isBlogPage) {
        seoDefaults = {
            title: 'Geotab Blog - Fleet Management Blog',
            description: 'Fleet Management Blog',
            canonical: fullUrl,
        };
    }

    if (seo && seo.title) {
        seo.title = ensureGeotabTitle(seo.title);
    }

    const getOGImage = (imageUrl) => {
        const extension = imageUrl.toLowerCase().split('.').pop();
        return supportedImageFormats.includes(extension) ? imageUrl : seoDefaults.imageUrl;
    };

    const ogImage = seo && seo.imageUrl ? getOGImage(seo.imageUrl) : seoDefaults.imageUrl;
    const ogUrl =
        seo && seo.canonical ? removeUrlParameters(seo.canonical) : removeUrlParameters(seoDefaults.canonical);

    return (
        <Helmet>
            <title>{seo && seo.title ? seo.title : seoDefaults.title}</title>
            {noIndex ? <meta name='robots' content='noindex' /> : null}
            {alternateLinks}
            <meta name='description' content={seo && seo.description ? seo.description : seoDefaults.description} />

            <meta property='og:title' content={seo && seo.title ? seo.title : seoDefaults.title} />
            <meta
                property='og:description'
                content={seo && seo.description ? seo.description : seoDefaults.description}
            />
            <meta property='og:image' content={ogImage} />
            <meta property='og:url' content={ogUrl} />
            {locale ? <meta property='og:locale' content={locale} /> : null}

            <meta name='twitter:title' content={seo && seo.title ? seo.title : seoDefaults.title} />
            <meta
                name='twitter:description'
                content={seo && seo.description ? seo.description : seoDefaults.description}
            />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:image' content={ogImage} />

            <link rel='canonical' href={ogUrl} />
            {
                <script type='application/ld+json'>
                    {`{
                    "@context": "https://schema.org",
                    "@graph": [
                        {
                            "@type": "WebSite",
                            "@id": "https://www.geotab.com/#website",
                            "url": "https://www.geotab.com/",
                            "name": "Geotab",
                            "description": "Fleet Management - Vehicle Tracking",
                            "potentialAction": {
                                "@type": "SearchAction",
                                "target": {
                                  "@type": "EntryPoint",
                                  "urlTemplate": "${searchUrl}?query={search_term_string}"
                                },
                                "query-input": "required name=search_term_string"
                            }
                        },
                        {
                            "@type": "WebPage",
                            "@id": "${fullUrl}#webpage",
                            "url": "${fullUrl || 'https://www.geotab.com/'}",
                            "inLanguage": "en-US",
                            "name": "${(seo && seo.title && seo.title.replace(/"/g, '')) || seoDefaults.title}",
                            "isPartOf": {
                                "@id": "https://www.geotab.com/#website"
                            },
                            "datePublished": "${(seo && seo.datePublished) || '2015-04-01T13:21:37+00:00'}",
                            "dateModified": "${(seo && seo.dateModified) || '2019-11-22T15:37:33+00:00'}",
                            "description": "${
                                (seo && seo.description && seo.description.replace(/"/g, '')) || seoDefaults.description
                            }"
                        }
                    ]
                }`}
                </script>
            }
        </Helmet>
    );
}

PageHelmet.defaultProps = {
    isBlogPage: false,
};

PageHelmet.propTypes = {
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        canonical: PropTypes.string,
        dateModified: PropTypes.string,
        datePublished: PropTypes.string,
    }),
    noIndex: PropTypes.bool,
    isBlogPage: PropTypes.bool,
};
