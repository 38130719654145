import { Validator } from '@web-for-marketing/react-ui';
import { getValidatorMessage, hasLength, isNotEmpty, regexMatches } from '@helpers/validators';
import { regexErrorMessages, regexPatterns } from './regexHelper';
import { isSlugSegmentLengthValid } from './errors';
import { hasValue, isLengthValid } from '@helpers/operand';

export const altValidators: Validator[] = [
    (value, values) =>
        (values && !values.image) || (values && values.decoration) || hasValue(value) ? null : 'Alt field is empty',
    (value, values) =>
        (values && !values.image) || (values && values.decoration) || isLengthValid({ min: 15 }, value)
            ? null
            : getValidatorMessage('Alt text should be more than 15 characters long', true),
];

export const titleValidators: Validator[] = [
    isNotEmpty('A title is required'),
    hasLength(
        { max: 100 },
        'Title should not be more than 100 characters. It is recommended to be between 20-70 characters',
        true
    ),
];

export const titleHeaderSectionValidator: Validator = (value, values) =>
    !hasValue(value) && values?.headerSection ? 'A title is required for the header section' : null;

export const descriptionValidators: Validator[] = [
    isNotEmpty('A description is required'),
    hasLength({ max: 160 }, 'Description should not be more than 160 characters', true),
];

export const slugValidators: Validator[] = [
    isNotEmpty('A slug is required'),
    regexMatches(regexPatterns.slug, regexErrorMessages.slug),
    (value) =>
        typeof value === 'string' && isSlugSegmentLengthValid(value, 80)
            ? null
            : { message: 'Each slug segment should not be over 80 characters', isWarning: true },
];

export const authorNotPublishedValidator: Validator = (value, values) => {
    if (values && Array.isArray(values.authorList) && values.authorList.length > 0) {
        const author = values.authorList.find((item) => item.id === value);
        if (author && author.status === 0) {
            return {
                message:
                    'The associated author is currently unpublished. This blog post will not be accessible to the public until the author is published.',
                isWarning: true,
            };
        }
    }
    return null;
};

export const breadcrumbDisplayNameValidator: Validator = hasLength(
    { max: 30 },
    'Breadcrumb display name should not be more than 30 characters',
    true
);

export const youtubeUrlValidators: Validator[] = [
    isNotEmpty('A Youtube URL is required'),
    (value) =>
        typeof value === 'string' && (value.includes('youtube.com/') || value.includes('youtu.be/'))
            ? null
            : 'That is not a valid Youtube URL. Please try another one.',
];

export const driveShareUrlValidators: Validator[] = [
    isNotEmpty('A Google Drive public share link is required'),
    (value) =>
        typeof value === 'string' && value.includes('drive.google.com/')
            ? null
            : 'That is not a valid Google Drive share link. Please try another one.',
];

export const blogFaqValidator: Validator = (value) => {
    if (Array.isArray(value) && value.length) {
        const isNotValid = value.some((content) => content.answer === '' || content.question === '');
        return isNotValid ? 'Please fill the required fields in the FAQ accordion' : null;
    }
    return null;
};
