import { atom } from 'jotai';
import { MyAdminUser, UserRole } from '@models/authentication';

export const myAdminUserAtom = atom<MyAdminUser>(null);

export const isAuthenticatedAtom = atom((get) => get(myAdminUserAtom) !== null);

export const userRolesAtom = atom((get) => {
    const myAdminUser = get(myAdminUserAtom);

    return myAdminUser && myAdminUser.apiUser && myAdminUser.apiUser.roles
        ? myAdminUser.apiUser.roles.map((role) => role.name).filter((role) => Object.values(UserRole).includes(role))
        : [];
});

export const loginAtom = atom(null, (_get, set, user: MyAdminUser) => {
    set(myAdminUserAtom, user);
});

export const logoutAtom = atom(null, (_get, set) => {
    set(myAdminUserAtom, null);
});
