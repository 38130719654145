import React, { useState } from 'react';
import { Button } from '@mui/material';
import { ChatBubble } from '@components/IconComponents/ChatBubble';
import { CloseIcon } from '@components/IconComponents/CloseIcon';
import { chatWidgetStyle as classes } from './styleObjects/chatWidget';

export function Chatbox(): JSX.Element | null {
    const [chatLoading, setChatLoading] = useState(false);
    const [isChatVisible, setIsChatVisible] = useState(true);

    function loadChatScript(): void {
        setChatLoading(true);
        const script = document.createElement('script');
        script.src = '/static/js/external/chatbox.js';
        document.body.appendChild(script);
    }

    function closeChat(event: React.MouseEvent): void {
        event.stopPropagation();
        setIsChatVisible(false);
    }

    if (!isChatVisible) {
        return null;
    }

    return chatLoading ? (
        <Button component='div' variant='contained' css={classes.chatIcon} id='chat-widget-button'>
            Loading...
        </Button>
    ) : (
        <Button variant='contained' css={classes.chatIcon} onClick={loadChatScript} id='chat-widget-button'>
            <>
                <ChatBubble />
                &nbsp;&nbsp;Live Chat &nbsp;
                <span css={classes.chatCloseIcon} data-testid='closeChat'>
                    <CloseIcon onClick={closeChat} />
                </span>
            </>
        </Button>
    );
}
