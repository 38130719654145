import * as Sentry from '@sentry/browser';

export function getErrorMessage(error) {
    if (error.error) {
        return getErrorMessage(error.error);
    }
    return error.message || 'Unknown error';
}

export function isSlugSegmentLengthValid(slug, maxLength) {
    const slugSegments = slug.split('/');
    return slugSegments.every((segment) => segment.length <= maxLength);
}

export function logError(error, taskDescription) {
    if (taskDescription) {
        console.error(`Error performing task: ${taskDescription}. See below for details.`);
    }
    console.error(error);
    Sentry.captureException(error);
}
