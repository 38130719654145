import React from 'react';
import { Link, Button, ButtonProps as MUIButtonProps, LinkProps as MUILinkProps } from '@mui/material';
import { Link as SPALink } from 'react-router-dom';
import { ensureInternalURLTrailingSlash, isInternalLink, ensureHttps, replaceValueInString } from '@helpers/string';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { generalFileUrlPrefixToken } from '@helpers/gallery';
import { getLinkTargetValue } from '@components/SectionComponents/helpers/SectionComponentHelper';

interface BaseProps {
    spaLink?: boolean;
    target?: React.HTMLAttributeAnchorTarget | boolean;
}

interface CMSLinkLinkProps extends BaseProps, Omit<MUILinkProps, keyof BaseProps> {
    type?: 'link';
    buttonClassName?: never;
}

interface CMSLinkButtonProps extends BaseProps, Omit<MUIButtonProps<'a'>, keyof BaseProps> {
    type: 'button';
    buttonClassName?: string;
}

export type CMSLinkProps = CMSLinkLinkProps | CMSLinkButtonProps;

/**
 * @deprecated Use the V2Button component instead.
 */
export function CMSLink({
    href,
    type = 'link',
    buttonClassName,
    children,
    spaLink,
    target,
    ...other
}: CMSLinkProps): JSX.Element {
    const { generalFileUrlPrefix } = useStaticValues();
    const replacedUrl = replaceValueInString(generalFileUrlPrefixToken, generalFileUrlPrefix, href);
    const targetValue = getLinkTargetValue(target);
    const targetSelf = targetValue === '_self' || (replacedUrl && replacedUrl.startsWith('#'));

    function getContent(): JSX.Element {
        if (isInternalLink(replacedUrl)) {
            const url = ensureInternalURLTrailingSlash(replacedUrl);
            return type === 'button' ? (
                <Button
                    data-testid='defaultButtonId'
                    data-spalink={spaLink}
                    component={spaLink ? SPALink : 'a'}
                    target={targetValue}
                    {...(other as MUIButtonProps<'a'>)}
                    to={spaLink ? url : undefined}
                    href={url}
                    classes={{ root: `${buttonClassName ? buttonClassName : ''}` }}
                >
                    {children}
                </Button>
            ) : (
                <Link
                    data-testid='defaultLinkid'
                    data-spalink={spaLink}
                    underline='none'
                    {...(other as MUILinkProps)}
                    target={targetValue}
                    href={url}
                    component={spaLink ? SPALink : 'a'}
                    to={spaLink ? url : undefined}
                >
                    {children}
                </Link>
            );
        } else {
            return type === 'button' ? (
                <Button
                    data-testid='notInternalLinkButtonId'
                    component='a'
                    href={ensureHttps(replacedUrl)}
                    {...(other as MUIButtonProps<'a'>)}
                    rel='noopener noreferrer'
                    target={targetSelf ? '_self' : '_blank'}
                    classes={{ root: `${buttonClassName ? buttonClassName : ''}` }}
                >
                    {children}
                </Button>
            ) : (
                <Link
                    data-testid='externalLinkid'
                    underline='none'
                    {...(other as MUILinkProps)}
                    href={ensureHttps(replacedUrl)}
                    rel='noopener noreferrer'
                    target={targetSelf ? '_self' : '_blank'}
                >
                    {children}
                </Link>
            );
        }
    }

    return getContent();
}
