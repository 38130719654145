import React from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { languages } from '@helpers/languages';
import { LanguageRouteKey } from '@models/languageRoute';

const BlogHomeLoadable = loadable(() => import('./pages/public/blog/BlogHome'));
const BlogCategoryLoadable = loadable(() => import('./pages/public/blog/BlogCategory'));
const PublicBlogLoadable = loadable(() => import('./pages/public/blog/PublicBlog'));
const BlogAuthorLoadable = loadable(() => import('./pages/public/blog/BlogAuthor'));
const ValidatePressReleaseLoadable = loadable(() => import('./pages/public/ValidatePressRelease'));
const ValidateSuccessStoryLoadable = loadable(() => import('./pages/public/ValidateSuccessStory'));
const WhitePapersHomeLoadable = loadable(() => import('./pages/public/WhitePapersHome'));
const WhitePaperLoadable = loadable(() => import('./pages/public/WhitePaper'));
const TagPageLoadable = loadable(() => import('./pages/public/TagPage'));
const MyGeotabLoginLoadable = loadable(() => import('./pages/public/MyGeotabLogin'));
const BecomeInstallerLoadable = loadable(() => import('./pages/public/BecomeInstaller'));
const VideoLoadable = loadable(() => import('./pages/public/Video'));
const DocumentLoadable = loadable(() => import('./pages/public/Document'));
const SitemapLoadable = loadable(() => import('./pages/public/Sitemap'));
const PublicSearchResultsLoadable = loadable(() => import('@pages/public/PublicSearchResults'));
const GlossaryLoadable = loadable(() => import('./pages/public/Glossary'));

export function LanguageRoutes(): JSX.Element {
    const routeList: { path: string; urlType: string }[] = [];

    languages.forEach((language) => {
        Object.keys(language.routes).forEach((urlType) => {
            const path = `${language.tempUrlPrefix || language.urlPrefix}${language.routes[urlType as LanguageRouteKey]}`;
            routeList.push({ path, urlType });
        });
    });

    return (
        <>
            {routeList.map(({ path, urlType }) => {
                switch (urlType) {
                    case 'blogHomeUrl':
                        return <Route path={path} key={`${urlType}-${path}`} element={<BlogHomeLoadable />} />;
                    case 'blogPostUrl':
                        return (
                            <Route path={`${path}:id`} key={`${urlType}-${path}`} element={<PublicBlogLoadable />} />
                        );
                    case 'authorUrl':
                        return (
                            <Route
                                path={`${path}:id/:page?`}
                                key={`${urlType}-${path}`}
                                element={<BlogAuthorLoadable />}
                            />
                        );
                    case 'categoryUrl':
                        return (
                            <Route
                                path={`${path}:id/:page?`}
                                key={`${urlType}-${path}`}
                                element={<BlogCategoryLoadable />}
                            />
                        );
                    case 'tagUrl':
                        return (
                            <Route
                                path={`${path}:id/:page?`}
                                key={`${urlType}-${path}`}
                                element={<TagPageLoadable />}
                            />
                        );
                    case 'pressRoomHomeUrl':
                        return (
                            <Route
                                path={`${path}:param?`}
                                key={`${urlType}-${path}`}
                                element={<ValidatePressReleaseLoadable />}
                            />
                        );
                    case 'pressReleaseUrl':
                        return (
                            <Route
                                path={`${path}:param`}
                                key={`${urlType}-${path}`}
                                element={<ValidatePressReleaseLoadable />}
                            />
                        );
                    case 'successStoriesHomeUrl':
                        return (
                            <Route
                                path={`${path}:param?`}
                                key={`${urlType}-${path}`}
                                element={<ValidateSuccessStoryLoadable />}
                            />
                        );
                    case 'caseStudyUrl':
                        return (
                            <Route
                                path={`${path}:param`}
                                key={`${urlType}-${path}`}
                                element={<ValidateSuccessStoryLoadable />}
                            />
                        );
                    case 'whitePapersHomeUrl':
                        return (
                            <Route
                                path={`${path}:page?`}
                                key={`${urlType}-${path}`}
                                element={<WhitePapersHomeLoadable />}
                            />
                        );
                    case 'whitePaperUrl':
                        return (
                            <Route path={`${path}:id`} key={`${urlType}-${path}`} element={<WhitePaperLoadable />} />
                        );
                    case 'glossaryUrl':
                        return (
                            <Route path={`${path}:param`} key={`${urlType}-${path}`} element={<GlossaryLoadable />} />
                        );
                    case 'myGeotabLoginUrl':
                        return <Route path={path} key={`${urlType}-${path}`} element={<MyGeotabLoginLoadable />} />;
                    case 'becomeInstallerUrl':
                        return <Route path={path} key={`${urlType}-${path}`} element={<BecomeInstallerLoadable />} />;
                    case 'videoUrl':
                        return <Route path={`${path}:slug`} key={`${urlType}-${path}`} element={<VideoLoadable />} />;
                    case 'documentationUrl':
                        return <Route path={`${path}:id`} key={`${urlType}-${path}`} element={<DocumentLoadable />} />;
                    case 'dynamicSitemap':
                        return <Route path={path} key={`${urlType}-${path}`} element={<SitemapLoadable />} />;
                    case 'sitemap':
                        return <Route path={path} key={`${urlType}-${path}`} element={<SitemapLoadable />} />;
                    case 'publicSearchUrl':
                        return (
                            <Route path={path} key={`${urlType}-${path}`} element={<PublicSearchResultsLoadable />} />
                        );
                    default:
                        break;
                }
            })}
        </>
    );
}
