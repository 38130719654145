import React, { useEffect } from 'react';
import { pageNavPadding as classes } from './styleObjects/pageNavPadding';
import { useAtom, useSetAtom } from 'jotai';
import { remSizeAtom } from '@atoms/appSettings';
import { isDateVisibleAtom } from '@atoms/date';

interface PageContainerProps {
    children?: JSX.Element;
}

export const PageContainer = ({ children }: PageContainerProps): JSX.Element => {
    const [remSize, setRemSize] = useAtom(remSizeAtom);
    const setDateVisibile = useSetAtom(isDateVisibleAtom);

    useEffect(() => {
        setRemSize(parseFloat(getComputedStyle(document.documentElement).fontSize));
    }, [setRemSize]);

    useEffect(() => {
        // only show date on client to avoid any server mismatch
        setDateVisibile(true);
    }, [setDateVisibile]);

    return (
        <main
            css={[classes.rootNavPadding, remSize >= 12 ? classes.noPadding : '']}
            id='mainContent'
            data-testid='page-container'
        >
            {children}
        </main>
    );
};
