import React from 'react';

export function LanguageListIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='314'
            height='295'
            viewBox='0 0 314 295'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <ellipse cx='168.478' cy='206.566' rx='88.4393' ry='88.4168' fill='#EAEDF1' />
            <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='80' y='118' width='177' height='177'>
                <ellipse cx='168.478' cy='206.566' rx='88.4393' ry='88.4168' fill='white' />
            </mask>
            <g mask='url(#mask0)'>
                <path
                    d='M147.641 138.768L150.866 131.058C155.587 119.77 145.819 109.051 134.997 114.761C104.684 130.756 62.757 167.469 85.8241 243.378C97.4229 271.424 101.779 286.842 103.319 295.123C104.341 300.623 105.752 307.387 110.554 310.257C115.24 313.056 120.664 312.646 120.664 295.736C120.664 270.715 141.748 242.721 148.468 234.434C149.789 232.806 150.823 230.966 151.448 228.965L155.241 216.827C158.756 205.577 148.402 194.581 136.664 195.651C120.468 197.127 103.739 195.424 108.46 180.797C111.178 172.375 117.61 169.874 125.155 170.407C137.375 171.27 152.191 159.427 147.59 148.074C146.379 145.086 146.398 141.741 147.641 138.768Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M218.942 176.327L234.573 162.804C239.557 158.492 246.741 157.943 251.888 162.06C273.661 179.479 323.523 230.016 264.209 291.44C246.593 307.642 236.86 317.686 231.495 323.771C227.208 328.632 220.583 332.5 214.102 332.641L180.536 333.376C165.94 333.695 159.293 314.771 170.506 305.424C174.594 302.016 178.528 298.638 182 295.5C193.453 285.149 203.5 278 210.344 267.945C214.588 261.711 216.901 251.594 218.046 244.806C218.797 240.354 217.133 235.91 213.899 232.758L201.541 220.713C196.142 215.451 195.489 207 200.015 200.971L216.76 178.665C217.402 177.81 218.134 177.027 218.942 176.327Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M175.988 138.716L178.804 120.228C179.935 112.807 186.378 107.366 193.885 107.492L198.117 107.563C200.708 107.607 203.243 108.32 205.476 109.635L209.604 112.065C217.061 116.454 219.268 126.223 214.423 133.39L203.502 149.541C198.946 156.279 189.849 158.158 182.995 153.777L182.739 153.613C177.741 150.418 175.094 144.579 175.988 138.716Z'
                    fill='#C1C9D6'
                />
            </g>
            <path
                opacity='0.1'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M133.442 278.417C182.299 278.417 221.906 238.82 221.906 189.975C221.906 161.653 208.59 136.44 187.875 120.253C227.393 129.1 256.923 164.382 256.923 206.558C256.923 255.403 217.317 295 168.459 295C147.931 295 129.036 288.01 114.026 276.28C120.274 277.679 126.772 278.417 133.442 278.417Z'
                fill='#112E5A'
            />
            <path
                d='M119.978 71.3597C119.978 80.5328 112.54 87.9694 103.364 87.9694C94.1882 87.9694 86.75 80.5328 86.75 71.3597C86.75 62.1866 94.1882 54.75 103.364 54.75C112.54 54.75 119.978 62.1866 119.978 71.3597Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M260.862 17.3597C260.862 26.5328 253.423 33.9694 244.247 33.9694C235.072 33.9694 227.633 26.5328 227.633 17.3597C227.633 8.1866 235.072 0.75 244.247 0.75C253.423 0.75 260.862 8.1866 260.862 17.3597Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M253.948 95.2617C253.948 98.475 251.342 101.08 248.127 101.08C244.913 101.08 242.307 98.475 242.307 95.2617C242.307 92.0484 244.913 89.4431 248.127 89.4431C251.342 89.4431 253.948 92.0484 253.948 95.2617Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M155.39 238.569C155.39 241.782 152.785 244.387 149.57 244.387C146.356 244.387 143.75 241.782 143.75 238.569C143.75 235.355 146.356 232.75 149.57 232.75C152.785 232.75 155.39 235.355 155.39 238.569Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                d='M126.39 16.5686C126.39 19.7819 123.785 22.3872 120.57 22.3872C117.356 22.3872 114.75 19.7819 114.75 16.5686C114.75 13.3552 117.356 10.75 120.57 10.75C123.785 10.75 126.39 13.3552 126.39 16.5686Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M72.3905 238.569C72.3905 241.782 69.7849 244.387 66.5702 244.387C63.3556 244.387 60.75 241.782 60.75 238.569C60.75 235.355 63.3556 232.75 66.5702 232.75C69.7849 232.75 72.3905 235.355 72.3905 238.569Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M312.39 117.569C312.39 120.782 309.785 123.387 306.57 123.387C303.356 123.387 300.75 120.782 300.75 117.569C300.75 114.355 303.356 111.75 306.57 111.75C309.785 111.75 312.39 114.355 312.39 117.569Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M211.782 148.957C211.782 152.171 209.177 154.776 205.962 154.776C202.748 154.776 200.142 152.171 200.142 148.957C200.142 145.744 202.748 143.139 205.962 143.139C209.177 143.139 211.782 145.744 211.782 148.957Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path d='M130.57 124V137.137' stroke='white' strokeWidth='1.5' />
            <path d='M137.141 130.569L124 130.569' stroke='white' strokeWidth='1.5' />
            <path d='M293.43 33.3779V46.5151' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M300 39.9465L286.86 39.9465' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M263.494 155.318V168.456' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M270.064 161.887L256.924 161.887' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M36.5702 260V273.137' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M43.1405 266.569L30 266.569' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M30.5702 47V60.1372' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M37.1405 53.5686L24 53.5686' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M216.612 240.541V248.698' stroke='white' strokeWidth='1.5' />
            <path d='M220.692 244.62L212.532 244.62' stroke='white' strokeWidth='1.5' />
            <circle cx='40' cy='124' r='40' fill='#EAEDF1' />
            <path
                opacity='0.1'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24.696 156.503C46.578 156.503 64.3168 138.602 64.3168 116.52C64.3168 103.716 58.3528 92.3179 49.0752 85C66.7741 88.9997 80 104.95 80 124.017C80 146.099 62.2612 164 40.3792 164C31.1853 164 22.7227 160.84 16 155.537C18.7984 156.17 21.7086 156.503 24.696 156.503Z'
                fill='#112E5A'
            />
            <circle cx='57.5' cy='131.5' r='4.75' stroke='white' strokeWidth='1.5' />
            <circle cx='48.5' cy='97.5' r='4.75' stroke='white' strokeWidth='1.5' />
            <circle cx='16.5' cy='146.5' r='4.75' stroke='white' strokeWidth='1.5' />
            <circle cx='23.5' cy='114.5' r='10.75' stroke='white' strokeWidth='1.5' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M172 31C164.574 31.0029 157.453 33.961 152.202 39.224C146.952 44.487 144.001 51.6242 144 59.0665C144 74.5614 172 104 172 104C172 104 200 74.5614 200 59.0665C199.999 51.6242 197.048 44.487 191.798 39.224C186.547 33.961 179.426 31.0029 172 31V31ZM172 69.6585C169.91 69.6584 167.866 69.0371 166.128 67.8732C164.39 66.7093 163.036 65.055 162.236 63.1195C161.436 61.1841 161.227 59.0544 161.635 56.9998C162.043 54.9451 163.049 53.0579 164.527 51.5766C166.005 50.0953 167.889 49.0866 169.939 48.6779C171.989 48.2693 174.114 48.4791 176.045 49.2808C177.976 50.0825 179.627 51.4401 180.788 53.1819C181.95 54.9238 182.57 56.9716 182.57 59.0665V59.0665C182.567 61.8749 181.452 64.5674 179.471 66.5532C177.489 68.539 174.802 69.6558 172 69.6585V69.6585Z'
                fill='#0078D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M191 59.505C191.001 55.8461 189.917 52.269 187.885 49.2261C185.853 46.1833 182.964 43.8113 179.584 42.4102C176.204 41.009 172.485 40.6416 168.896 41.3545C165.307 42.0673 162.01 43.8284 159.422 46.415C156.834 49.0015 155.071 52.2974 154.356 55.8859C153.642 59.4743 154.007 63.1942 155.406 66.575C156.806 69.9558 159.176 72.8458 162.218 74.8794C165.26 76.913 168.836 77.999 172.495 78V78C177.399 77.9947 182.102 76.0447 185.571 72.5777C189.039 69.1106 190.992 64.4095 191 59.505ZM172.5 69.3391C170.555 69.339 168.654 68.7622 167.037 67.6816C165.419 66.6009 164.159 65.065 163.415 63.268C162.671 61.4711 162.476 59.4938 162.855 57.5862C163.235 55.6786 164.172 53.9264 165.547 52.5511C166.922 51.1758 168.675 50.2393 170.582 49.8599C172.49 49.4804 174.467 49.6752 176.264 50.4196C178.061 51.1639 179.597 52.4244 180.677 54.0416C181.758 55.6588 182.335 57.5601 182.335 59.505V59.505C182.332 62.1125 181.295 64.6123 179.451 66.456C177.607 68.2997 175.107 69.3366 172.5 69.3391V69.3391Z'
                fill='#0059B3'
            />
        </svg>
    );
}
