export const regexPatterns: Record<string, RegExp> = {
    slug: /^[\p{Ll}\p{Lo}\d/-]*\/$/u,
    fleetSize: /^[\d+]+$/,
    requireHttpHttps: /^(http|https):\/\//,
};

export const regexErrorMessages: Record<string, string> = {
    slug: 'The URL Slug may only contain lowercase letters, numbers, slashes or dashes, and must end with a trailing forward slash.',
    fleetSize: 'The fleet size should contain numbers and + sign only',
    linkedInLink: 'LinkedIn link needs to start with http:// or https://',
    twitterLink: 'Twitter link needs to start with http:// or https://',
    facebookLink: 'Facebook link needs to start with http:// or https://',
    instagramLink: 'Instagram link needs to start with http:// or https://',
};
