import React from 'react';

export function CloseIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            color='currentColor'
            fill='none'
            strokeLinecap='round'
            strokeWidth='2px'
            height='1em'
            width='1em'
            stroke='currentColor'
            {...props}
        >
            <g>
                <line x1='1.5' y1='1.5' x2='18.5' y2='18.5' />
                <line x1='18.5' y1='1.5' x2='1.5' y2='18.5' />
            </g>
        </svg>
    );
}
