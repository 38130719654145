import React from 'react';
import { Grid } from '@mui/material';
import { setCookie } from '@services/cookieService';
import { CMSLink } from '@components/CMSLink';
import { languageListStyles as classes } from './styleObjects/languageListStyles';
import { Text } from '@web-for-marketing/react-ui';
import { CookieKey } from '@models/cookie';

interface LanguageProps {
    code: string;
    urlPrefix: string;
    name: string;
}

interface LanguageListProps {
    title: string;
    languages: LanguageProps[];
}

const setLanguageCookies = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const code = event.currentTarget.getAttribute('data-code');
    if (code) {
        setCookie(CookieKey.LanguageCodePreference, code);
        setCookie(CookieKey.LanguageSuggestionBanner, true, 1);
    }
};

export function LanguageList({ title, languages }: LanguageListProps): JSX.Element {
    return (
        <>
            <Grid item xs={12}>
                <Text variant='body1' css={classes.title}>
                    {title}
                </Text>
            </Grid>
            <Grid container>
                {languages.map(({ code, urlPrefix, name }) => (
                    <Grid key={code} item xs={12} data-testid={`${code}Grid`}>
                        <Text
                            variant='body2'
                            css={classes.languageLink}
                            component={CMSLink}
                            href={urlPrefix}
                            data-testid={`${code}Link`}
                            onClick={setLanguageCookies}
                            data-code={code}
                        >
                            {name}
                        </Text>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
