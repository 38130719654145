import React from 'react';
import { isMobileAtom } from '@atoms/appSettings';
import { isNavbarDisabledAtom } from '@atoms/navigation';
import loadable from '@loadable/component';
import { Hidden } from '@mui/material';
import { useAtom } from 'jotai';

const NavigationBarLoadable = loadable(() => import('./NavigationBar'));
const MobileNavigationBarLoadable = loadable(() => import('./MobileNavigationBar'));
const LanguageSuggestionBannerLoadable = loadable(() => import('./LanguageSuggestionBanner'));

export function AccessibleNav(): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [isNavbarDisabled] = useAtom(isNavbarDisabledAtom);
    const initialWidth = isMobile ? 'sm' : 'lg';

    return (
        <>
            {!isNavbarDisabled ? (
                <>
                    <Hidden initialWidth={initialWidth} mdDown>
                        <NavigationBarLoadable />
                    </Hidden>
                    <Hidden initialWidth={initialWidth} mdUp>
                        <MobileNavigationBarLoadable />
                    </Hidden>
                </>
            ) : null}
            <LanguageSuggestionBannerLoadable />
        </>
    );
}
