import React from 'react';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { v2Colors, breakpoints } from '@web-for-marketing/react-ui';

const skipToContent = {
    color: v2Colors.core.geotab,
    backgroundColor: v2Colors.core.snow,
    padding: '1.4rem 2rem',
    position: 'absolute',
    fontSize: '2rem',
    top: 0,
    left: -999,
    opacity: 0,
    height: 1,
    width: 1,
    zIndex: -999,
    '&:focus': {
        opacity: 1,
        left: 0,
        height: 'auto',
        width: 'auto',
        zIndex: 999,
    },
    '@media (max-width: 1439.9px)': {
        fontSize: '1.8rem',
    },
    [`@media (max-width: ${breakpoints.md}px)`]: {
        fontSize: '1.6rem',
    },
} as const;

export function SkipToContentLink(): JSX.Element {
    const { fullUrl } = useStaticValues();

    return (
        <a
            tabIndex={0}
            href={!fullUrl.includes('/blog') ? '#mainContent' : '#blogMainContent'}
            id='btnSkipToContent'
            css={skipToContent}
        >
            Skip to main content
        </a>
    );
}
