import CorpProfile from '@images/PressRoom/icon-corporate-profile.svg';
import LeadershipBio from '@images/PressRoom/icon-leadership-bios.svg';
import MediaGuidelines from '@images/PressRoom/icon-media-guidelines.svg';
import LegalPolicy from '@images/PressRoom/icon-legal-policy.svg';
import MediaContact from '@images/PressRoom/icon-media-contact.svg';
import PkBackground from '@images/PressRoom/presskit-background@2x.jpg';

export const PressRoomImages = {
    corpProfile: CorpProfile,
    leadershipBio: LeadershipBio,
    mediaGuidelines: MediaGuidelines,
    legalPolicy: LegalPolicy,
    mediaContact: MediaContact,
    pkBackground: PkBackground,
};
