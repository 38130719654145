import { colors, breakpoints, v2Colors } from '@web-for-marketing/react-ui';
import { footerBackgroundColors } from '@helpers/footer';
import { FooterBackground } from '@models/footer';

export const footerStyles = {
    textLink: {
        fontSize: '1.4rem',
        color: v2Colors.core.smoke,
        display: 'block',
        padding: '0.4rem 2rem 0.4rem 0',
        margin: '0.3rem 0',
        minWidth: '33%',
        transition: 'background-color 100ms ease, color 100ms ease',
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        '&:hover, &:hover > *': {
            color: v2Colors.core.snow,
            backgroundColor: `${colors.core.slate.main}`,
        },
        '&:focus, &:focus > *': {
            backgroundColor: colors.core.slate.main,
        },
        '& > *': {
            color: v2Colors.core.smoke,
        },
    },

    regionLink: {
        display: 'inherit !important',

        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },

    bottomText: {
        fontSize: '1.4rem',
        color: v2Colors.core.smoke,
    },
    bottomLinks: {
        display: 'inline-block',
        minWidth: 0,
        padding: '0.3rem 0.5rem',
        borderRadius: 2,
        textDecoration: 'none',

        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '0.5rem 0.8rem',
        },
    },
    bottomButtonLink: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
    },
    separator: {
        display: 'inline',
        color: v2Colors.core.smoke,
        margin: '0 0.6rem',
        '&:last-child': {
            display: 'none',
        },

        [`@media (max-width: ${breakpoints.md}px)`]: {
            margin: '0 0.4rem',
        },
    },
    linkSection: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flex: '1 0 auto',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '100%',
            '&:last-child > * > *:last-child > *:last-child > *:last-child': {
                borderBottom: 'none',
            },
        },
    },
    newHeader: {
        marginTop: '3rem',
    },
    image: {
        width: '80%',
        objectFit: 'cover',
        color: v2Colors.core.snow,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '100%',
        },
    },
    topFooterSpacing: {
        marginTop: '2rem',
        marginBottom: '0.3rem',

        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '.5em',
        },
    },
    copyRightText: {
        color: v2Colors.core.smoke,
        fontSize: '1.4rem',
        marginTop: '1.1rem',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            fontSize: '1.4rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.2rem',
            marginTop: '2rem',
        },
    },
    footerTypographyRoot: {
        marginBottom: '1.5rem',
    },
    accessibilityHidden: {
        height: 1,
        width: 1,
        clip: 'rect(1px,1px,1px,1px)',
        opacity: 0.1,
        whiteSpace: 'nowrap',
        color: v2Colors.core.deepSpace,
        position: 'absolute',
        margin: 0,
        overflow: 'hidden',
        zIndex: -1,
        top: 0,
        left: 0,
    },
    footer: (background?: FooterBackground | null) =>
        ({
            bottom: 0,
            position: 'relative',
            backgroundColor: background
                ? footerBackgroundColors[background]
                : footerBackgroundColors[FooterBackground.DarkBlue],
            width: '100%',
        }) as const,
    pad: {
        paddingTop: '4rem',
        paddingBottom: '1.5rem',
    },
    footerContainer: {
        margin: '0 1rem',
        '@media (max-width: 1179.9px)': {
            padding: '4.5rem 3vw',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '3rem 3.6rem 1.5rem',
            '&:last-child': {
                padding: '3rem 3.6rem 3rem',
            },
        },
    },
    footnotes: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
    },
    footerLogo: {
        maxWidth: '15rem',
    },
    noBorder: {
        border: 'none',
    },
    topBorder: {
        borderTop: `1px solid ${v2Colors.core.slate}`,
        opacity: 1,
        paddingTop: '4rem',
        margin: 0,
        width: '100%',
    },
    socialFlexBox: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        '@media (max-width: 769.9px)': {
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
    },
    shareIcon: {
        margin: '0 .5rem',
        padding: '.5rem',
        borderRadius: 2,
        transition: 'background-color 100ms ease',
        '& > svg': {
            width: '3rem',
            height: '3rem',
            color: v2Colors.core.smoke,
            verticalAlign: 'bottom',
        },
        '&:hover': {
            backgroundColor: `${colors.core.slate.main}`,
        },
        '&:hover > svg': {
            color: v2Colors.core.snow,
        },
        '&:focus > svg': {
            color: v2Colors.core.snow,
        },
        '&:focus': {
            backgroundColor: colors.core.slate.main,
        },

        [`@media (max-width: ${breakpoints.md}px)`]: {
            float: 'left',
            margin: '0 2rem 2rem 0',
        },
    },
    socilaMediaIcon: {
        width: '3rem',
        height: '3rem',
        verticalAlign: 'bottom',
    },
    centerAlignMobile: {
        '@media (max-width: 769.9px)': {
            textAlign: 'left',
        },
    },
    orderFooter: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            order: 2,
        },
    },
    languageLink: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            justifyContent: 'flex-start',
            width: '100%',
            '&:before': {
                content: '""',
                borderTop: '1px solid #66788C',
                opacity: 1,
                margin: 0,
                width: '100%',
                paddingTop: '2rem',
                marginTop: '-2rem',
            },
        },
        [`@media (min-width: ${breakpoints.md}px)`]: {
            '&:after': {
                content: '""',
                borderTop: '1px solid #66788C',
                opacity: 1,
                paddingTop: '4rem',
                margin: 0,
                width: '100%',
            },
        },
    },
    bottomDivider: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            borderBottom: 'unset',
            '&:last-child': {
                paddingBottom: '2rem',
                borderBottom: `1px solid ${v2Colors.core.slate}`,
            },
        },
    },
    linkBottomRoot: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            float: 'left',
        },
    },
} as const;
