import React from 'react';

export function GlobeIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='15'
            height='15'
            viewBox='0 0 15 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.125 7.66916C14.1259 9.37824 13.4478 11.0177 12.2399 12.2268C11.0321 13.4359 9.39333 14.1157 7.68425 14.1166C4.10998 14.0572 1.25148 11.1282 1.27925 7.55355C1.28211 4.13914 4.01204 1.35241 7.42566 1.27924C7.51838 1.27533 7.61109 1.27365 7.70492 1.27365C9.40618 1.26443 11.0408 1.93448 12.2461 3.13517C13.4514 4.33585 14.1277 5.96787 14.125 7.66916Z'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.42565 1.27924C4.07459 4.90955 4.07459 9.64572 7.42565 14.1138'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.98413 1.27924C11.3352 4.90956 11.3352 9.64405 7.98413 14.1121'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.33264 4.06287H13.0192'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M1.28088 7.41394H14.125'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.10864 10.765H13.3347'
                stroke='#66788C'
                strokeWidth='0.744681'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
