export interface AuthenticationSettings {
    oAuthClientId: string;
    myAdminSource: string;
}

export enum UserRole {
    GeotabComCmsAdmin = 'GeotabCom-CmsAdmin',
    GeotabComArticleAdmin = 'GeotabCom-ArticleAdmin',
    GeotabComArticleEditor = 'GeotabCom-ArticleEditor',
}

export type MyAdminUser = null | {
    apiUser: {
        name: string;
        roles: {
            name: UserRole;
            comments: string | null;
        }[];
    };
    sessionId: string;
};
