import React, { useMemo } from 'react';
import { getAliasByCode, getFlagPathByCode, getLanguageNameByCode } from '@helpers/languages';
import { GlobeIcon } from '@components/IconComponents/GlobeIcon';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';

const classes = {
    worldIcon: {
        marginLeft: '0rem !important',
        marginRight: '0.5rem',
    },
    flag: {
        marginLeft: '0.5rem',
        position: 'relative',
        top: '-0.2rem',
    },
} as const;

interface SelectedLanguageProps {
    languageCode: string;
    displayLongForm?: boolean;
    flagPath?: string;
}

export function SelectedLanguage({
    languageCode,
    displayLongForm = false,
    flagPath = '',
}: SelectedLanguageProps): JSX.Element {
    const { selectedLanguageTranslation } = useTranslation();
    const regionSelectionTranslation = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'regionSelection');
    }, [selectedLanguageTranslation]);
    const languageName = getLanguageNameByCode(languageCode);
    const selectedFlagPath = flagPath || getFlagPathByCode(languageCode);

    return (
        <>
            <GlobeIcon css={classes.worldIcon} data-testid={'selectedLanguageIcon'} />
            <span data-testid='selectedLanguageText'>
                {displayLongForm
                    ? `${regionSelectionTranslation.regionChangeText}: ${languageName}`
                    : getAliasByCode(languageCode)?.toUpperCase()}
            </span>
            {selectedFlagPath ? (
                <>
                    <PageHelmetPreloadLink as='image' href={selectedFlagPath} />
                    <img
                        src={selectedFlagPath}
                        css={classes.flag}
                        alt={`${languageName} region flag`}
                        data-testid='selectedLanguageFlag'
                        height='20'
                        width='20'
                    />
                </>
            ) : null}
        </>
    );
}
