import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '@icon/CloseIcon';
import { SnackbarContent, IconButton, Snackbar, Grid } from '@mui/material';
import { NotificationContext } from '../contexts/NotificationContext';
import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';
import { NavErrorIcon } from '@icon/NavErrorIcon';
import { WarningIcon, SuccessIcon, InfoIcon } from '@web-for-marketing/react-ui';

const variantIcon = {
    success: SuccessIcon,
    warning: WarningIcon,
    info: InfoIcon,
    error: NavErrorIcon,
};

const classes = {
    success: {
        backgroundColor: v2Colors.core.meadow,
        borderRadius: '8px',
    },
    error: {
        backgroundColor: v2Colors.core.ember,
        borderRadius: '8px',
    },
    info: {
        backgroundColor: v2Colors.core.innovation,
        borderRadius: '8px',
    },
    warning: {
        backgroundColor: v2Colors.core.mustard,
        borderRadius: '8px',
    },
    icon: {
        fontSize: '1.2rem',
    },
    iconVariant: {
        marginRight: '2.5rem',
        '& path': {
            fill: 'currentColor',
        },
        '& svg': {
            height: '2rem',
            width: '2rem',
        },
    },
    messageText: {
        fontSize: '1.4rem',
        color: v2Colors.core.snow,
        lineHeight: 1.3,
        margin: 0,
        whiteSpace: 'pre-line',
        '&::first-line': {
            fontWeight: 700,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.2rem',
        },
        '@media (max-width: 1080px)': {
            fontSize: '1.4rem',
        },
    },
};

const SnackBarVariant = React.forwardRef(function SnackBarVariantComponent(props, ref) {
    const { message, onClose, variant, subtitleMessage = '' } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            ref={ref}
            css={classes[variant]}
            aria-describedby='client-snackbar'
            message={
                <Grid container id='client-snackbar' alignItems='center'>
                    <Icon css={[classes.icon, classes.iconVariant]} />
                    <p css={classes.messageText}>
                        {message}
                        <br />
                        {subtitleMessage ? subtitleMessage : null}
                    </p>
                </Grid>
            }
            action={
                <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose} size='large'>
                    <CloseIcon />
                </IconButton>
            }
        />
    );
});

SnackBarVariant.propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    subtitleMessage: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
export function CustomSnackbar({ variant, message, autoHideDuration = 6000, subtitleMessage = '' }) {
    const notificationContext = useContext(NotificationContext);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        notificationContext.setOpen({ ...notificationContext.open, state: false, message: '', subtitleMessage: '' });
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={notificationContext.open.state}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
            >
                <SnackBarVariant
                    onClose={handleClose}
                    variant={variant}
                    message={message}
                    subtitleMessage={subtitleMessage}
                />
            </Snackbar>
        </div>
    );
}

CustomSnackbar.propTypes = {
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    message: PropTypes.string,
    autoHideDuration: PropTypes.number,
    subtitleMessage: PropTypes.string,
};
