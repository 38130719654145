import React, { createContext, useContext } from 'react';
import { StaticValues } from '@models/staticValues';

interface StaticValuesProviderProps {
    children: React.ReactNode;
    staticValues: StaticValues;
}

const StaticValuesContext = createContext<StaticValues | undefined>(undefined);

export const StaticValuesProvider = ({ staticValues, children }: StaticValuesProviderProps): JSX.Element => {
    return <StaticValuesContext.Provider value={staticValues}>{children}</StaticValuesContext.Provider>;
};

export const useStaticValues = (): StaticValues => {
    const context = useContext(StaticValuesContext);
    if (context === undefined) {
        throw new Error('useStaticValues must be used within a StaticValuesProvider');
    }

    return context;
};
