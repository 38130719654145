import { en } from '../translations/en';

export const genericFallbackMessage = '[Missing Translation]';
const missingFileFallbackMessage = '[Missing Translation File]';
const defaultFallbackLanguage = en;

export const getTranslation = (selectedLanguageTranslation, ...languagePropertyKeys) => {
    let translatedString;

    if (!selectedLanguageTranslation) {
        return missingFileFallbackMessage;
    }

    translatedString = getValueFromTranslationObject(selectedLanguageTranslation, languagePropertyKeys);

    if (translatedString !== '' && !translatedString) {
        translatedString = getValueFromTranslationObject(defaultFallbackLanguage, languagePropertyKeys);
    }

    return translatedString === '' || translatedString ? translatedString : genericFallbackMessage;
};

const getValueFromTranslationObject = (translationObject, languagePropertyKeysArray) => {
    let translatedString;

    if (languagePropertyKeysArray && languagePropertyKeysArray.length) {
        let currentTranslationObject = translationObject;

        for (let i = 0; i < languagePropertyKeysArray.length; i++) {
            if (Object.prototype.hasOwnProperty.call(currentTranslationObject, languagePropertyKeysArray[i])) {
                if (i == languagePropertyKeysArray.length - 1) {
                    translatedString = currentTranslationObject[languagePropertyKeysArray[i]];
                } else {
                    currentTranslationObject = currentTranslationObject[languagePropertyKeysArray[i]];
                }
            } else {
                break;
            }
        }
    }

    return translatedString;
};
