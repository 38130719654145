export const keyIsEnter = (event) => {
    return event.key === 'Enter' || event.code === 'Enter' || event.key === 'Return' || event.code === 'Return';
};
export const keyIsTab = (event) => {
    return event.key === 'Tab' || event.code === 'Tab';
};
export const keyIsSpace = (event) => {
    return event.key === ' ' || event.code === 'Space';
};
export const keyIsBackspace = (event) => {
    return event.key === 'Backspace' || event.code === 'Backspace' || event.code === 'Delete';
};
export const keyIsEscape = (event) => {
    return event.key === 'Escape' || event.code === 'Escape';
};
export const keyIsDown = (event) => {
    return event.key === 'ArrowDown' || event.code === 'ArrowDown';
};
export const keyIsUp = (event) => {
    return event.key === 'ArrowUp' || event.code === 'ArrowUp';
};
