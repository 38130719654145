import React from 'react';

export function LanguageListMobileIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='68'
            height='95'
            viewBox='0 0 68 95'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <ellipse cx='34.9337' cy='66.5214' rx='28.4804' ry='28.4732' fill='#EAEDF1' />
            <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='6' y='38' width='58' height='57'>
                <ellipse cx='34.9337' cy='66.5214' rx='28.4804' ry='28.4732' fill='white' />
            </mask>
            <g mask='url(#mask0)'>
                <path
                    d='M28.2235 44.6879L28.5215 43.9754C30.3507 39.6019 26.5613 35.4951 22.4711 37.8918C12.9353 43.4797 1.30625 55.3076 8.31627 78.3759C10.6151 83.9345 12.0304 87.9527 12.8946 90.8427C14.1741 95.1217 18.3377 102.569 19.2828 98.2041C19.4436 97.4616 19.5361 96.4899 19.5361 95.237C19.5361 87.4888 25.8144 78.8552 28.2207 75.8307C28.8177 75.0804 29.2918 74.239 29.5778 73.3238C31.1853 68.1789 26.4111 63.3395 21.0245 63.1291C17.2966 62.9835 14.4479 61.8101 15.6058 58.2229C16.4812 55.5106 18.5524 54.7052 20.9823 54.8768C24.9175 55.1546 29.6888 51.3408 28.207 47.6848C27.817 46.7227 27.823 45.6455 28.2235 44.6879Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M51.7641 56.2821L52.2234 55.8847C56.2027 52.4421 62.0218 51.9807 65.7227 55.7206C73.083 63.1587 81.7715 77.2748 65.7622 93.8536C61.3486 97.9128 58.4716 100.772 56.5981 102.764C54.3365 105.169 50.8926 107.094 47.5917 107.166L43.707 107.251C37.4832 107.387 34.6702 99.3349 39.2881 95.161C42.9763 91.8275 46.2119 89.5254 48.4158 86.2875C49.4212 84.8105 50.0901 82.6569 50.5203 80.7513C51.1124 78.1283 50.0886 75.4706 48.1627 73.5935C44.9475 70.4597 44.5585 65.4269 47.254 61.8364L50.0223 58.1489C50.5347 57.4664 51.1186 56.8406 51.7641 56.2821Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M37.3579 44.6327L38.1013 39.7527C38.5557 36.7701 41.153 34.583 44.1701 34.6338C45.2051 34.6512 46.2201 34.9363 47.1121 35.4614C50.0909 37.2148 50.9727 41.1173 49.0369 43.9802L46.2455 48.1086C44.7601 50.3055 41.7939 50.9181 39.5591 49.4897C37.9294 48.4481 37.0667 46.5443 37.3579 44.6327Z'
                    fill='#C1C9D6'
                />
            </g>
            <path
                opacity='0.1'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23.6508 89.6598C39.3846 89.6598 52.1394 76.9082 52.1394 61.1785C52.1394 52.0577 47.8511 43.9383 41.1802 38.7256C53.9063 41.5747 63.4161 52.9364 63.4161 66.5187C63.4161 82.2485 50.6613 95 34.9275 95C28.3168 95 22.232 92.7489 17.3981 88.9716C19.4103 89.4221 21.5028 89.6598 23.6508 89.6598Z'
                fill='#112E5A'
            />
            <path
                d='M19.0566 22.9802C19.0566 25.7915 16.777 28.0707 13.9647 28.0707C11.1525 28.0707 8.87289 25.7915 8.87289 22.9802C8.87289 20.169 11.1525 17.8898 13.9647 17.8898C16.777 17.8898 19.0566 20.169 19.0566 22.9802Z'
                stroke='#D8DEE6'
            />
            <path
                d='M64.4258 5.59042C64.4258 8.40166 62.1462 10.6808 59.334 10.6808C56.5217 10.6808 54.2421 8.40166 54.2421 5.59042C54.2421 2.77918 56.5217 0.5 59.334 0.5C62.1462 0.5 64.4258 2.77918 64.4258 5.59042Z'
                stroke='#D8DEE6'
            />
            <ellipse cx='60.5835' cy='30.6775' rx='2.11584' ry='2.11531' fill='#D8DEE6' />
            <ellipse cx='28.8447' cy='76.8272' rx='2.11584' ry='2.11531' fill='white' />
            <ellipse cx='19.5057' cy='5.33565' rx='2.11584' ry='2.11531' fill='#D8DEE6' />
            <ellipse cx='2.11584' cy='76.8272' rx='2.11584' ry='2.11531' fill='#D8DEE6' />
            <ellipse cx='47.0047' cy='47.9694' rx='2.11584' ry='2.11531' fill='white' />
            <path d='M19.1159 46V50.2306' stroke='white' />
            <path d='M21.2317 48.1153L17 48.1153' stroke='white' />
            <path d='M65.5319 50.0178V54.2484' stroke='#D8DEE6' />
            <path d='M67.6478 52.1331L63.4161 52.1331' stroke='#D8DEE6' />
            <path d='M50.4344 77.4623V80.0893' stroke='white' />
            <path d='M51.7483 78.7758L49.1206 78.7758' stroke='white' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.5 9C32.9804 9.00098 30.5643 10.0046 28.7829 11.7903C27.0015 13.576 26.0005 15.9975 26 18.5226C26 23.7797 35.5 33.7679 35.5 33.7679C35.5 33.7679 45 23.7797 45 18.5226C44.9995 15.9975 43.9985 13.576 42.2171 11.7903C40.4357 10.0046 38.0196 9.00098 35.5 9V9ZM35.5 22.1163C34.7908 22.1163 34.0975 21.9055 33.5078 21.5105C32.9181 21.1156 32.4586 20.5544 32.1872 19.8977C31.9158 19.241 31.8448 18.5184 31.9832 17.8213C32.1216 17.1242 32.4631 16.4839 32.9646 15.9813C33.4661 15.4788 34.1051 15.1365 34.8007 14.9979C35.4963 14.8592 36.2173 14.9304 36.8725 15.2024C37.5277 15.4744 38.0878 15.935 38.4818 16.526C38.8758 17.117 39.0861 17.8118 39.0861 18.5226V18.5226C39.0851 19.4754 38.707 20.389 38.0347 21.0627C37.3623 21.7365 36.4508 22.1154 35.5 22.1163V22.1163Z'
                fill='#0078D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M41.9463 18.6713C41.9467 17.4298 41.5789 16.2162 40.8895 15.1838C40.2 14.1514 39.22 13.3466 38.0732 12.8712C36.9264 12.3958 35.6643 12.2712 34.4467 12.5131C33.2291 12.7549 32.1105 13.3524 31.2324 14.23C30.3544 15.1076 29.7562 16.2258 29.5137 17.4434C29.2712 18.6609 29.3952 19.9229 29.8699 21.07C30.3447 22.2171 31.1489 23.1976 32.1809 23.8876C33.213 24.5776 34.4264 24.946 35.6679 24.9464V24.9464C37.3319 24.9445 38.9273 24.283 40.1042 23.1066C41.2812 21.9303 41.9436 20.3353 41.9463 18.6713ZM35.6696 22.0078C35.0097 22.0078 34.3646 21.8121 33.8159 21.4454C33.2672 21.0788 32.8396 20.5577 32.5871 19.948C32.3346 19.3383 32.2685 18.6675 32.3973 18.0202C32.5261 17.373 32.8438 16.7785 33.3105 16.3119C33.7771 15.8453 34.3716 15.5275 35.0189 15.3988C35.6661 15.2701 36.337 15.3362 36.9466 15.5887C37.5563 15.8412 38.0774 16.2689 38.444 16.8176C38.8106 17.3663 39.0063 18.0114 39.0063 18.6713V18.6713C39.0054 19.5559 38.6536 20.4041 38.028 21.0296C37.4024 21.6552 36.5542 22.007 35.6696 22.0078V22.0078Z'
                fill='#0059B3'
            />
        </svg>
    );
}
