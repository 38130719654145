export enum FooterBackground {
    DarkBlue = 'Dark blue',
    Black = 'Black',
}

export type FooterNavigation = {
    bottomContent: FooterBottomContent[];
    topContent: FooterTopContent[];
    copyRightText: string;
    id: number;
    isLanguageDefault: boolean;
    language: number;
    name: string;
    showLanguageRegion: boolean;
    socialMediaLinks?: FooterSocialMediaLink[];
    backgroundColor: FooterBackground | null;
};

export type NullableFooterNavigation = FooterNavigation | null;

export interface FooterBottomContent {
    rel?: string;
    href?: string;
    type: 'link';
    title?: string;
    isTargetToNewTab?: boolean;
}

export interface FooterTopContentBase {
    column: number;
    title: string;
}

export interface FooterTopContentLinkGroup extends FooterTopContentBase {
    type: 'sectionLink';
    links: FooterTopContentLink[];
}

export interface FooterTopContentImageLink extends FooterTopContentBase {
    type: 'imageLink';
    imagePath: string;
    imageAlt: string;
    href: string;
    isTargetToNewTab: boolean;
}

export type FooterTopContent = FooterTopContentLinkGroup | FooterTopContentImageLink;

export interface FooterSocialMediaLink {
    title: string;
    imagePath: string;
    href: string;
    type: 'socialMediaLink';
}

export interface FooterTopContentLink {
    href: string;
    isTargetToNewTab: boolean;
    title: string;
}

export type FooterFormType = FooterTopContent | FooterBottomContent | FooterSocialMediaLink;

export type FooterTopContentType = FooterTopContent['type'];

export type FooterBottomContentType = FooterBottomContent['type'];

export type FooterSocialMediaLinkType = FooterSocialMediaLink['type'];
