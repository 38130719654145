import { languageRoutes } from './languageRoutes';
import { LanguageCode } from '@models/languageCode';
import { LanguageRoute, LanguageRouteKey } from '@models/languageRoute';
import { es, frCa, de, it, fr, ptBr, id } from '@models/dayJsLanguageCodes';
import { MobileDemoBannerType } from '@models/mobileDemoBanner';
import { CountryFlags } from '@helpers/staticImages/countryFlags';

export const languageRegions = Object.freeze({
    Global: 'Global',
    America: 'America',
    Europe: 'Europe',
    Asia: 'Asia',
    Australia: 'Australia',
    MiddleEast: 'MiddleEast',
});

export interface Language {
    id: number;
    code: LanguageCode;
    alias: string;
    dropDownOrder: number;
    showInDropDown: boolean;
    urlPrefix: string;
    tempUrlPrefix?: string;
    dayjsLanguageCode?: string;
    name: string;
    region: string;
    routes: LanguageRoute;
    formDefaults: {
        noIndex?: boolean;
    };
    showGeotabSubscribe: boolean;
    isEnglish: boolean;
    isEuLanguage: boolean;
    enableSalesForceExtraField: {
        requestDemo: boolean;
        contactUsForm: boolean;
        platformPartnersForm: boolean;
    };
    flagPath: string;
    locale: string;
    disableLanguageSelection?: boolean;
    mobileDemoBannerType: MobileDemoBannerType;
    enableChatbox: boolean;
}

export const languages: Language[] = [
    {
        id: 1,
        code: LanguageCode.en,
        alias: '',
        dropDownOrder: 0,
        showInDropDown: true,
        urlPrefix: '/',
        name: 'WorldWide (English)',
        region: languageRegions.Global,
        routes: languageRoutes.en,
        formDefaults: {
            noIndex: false,
        },
        showGeotabSubscribe: true,
        isEnglish: true,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.worldwide,
        locale: 'en_US',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: true,
    },
    {
        id: 2,
        code: LanguageCode.enGb,
        alias: '',
        dropDownOrder: 1,
        showInDropDown: true,
        urlPrefix: '/uk/',
        name: 'UK (English)',
        region: languageRegions.Europe,
        formDefaults: {
            noIndex: false,
        },
        routes: languageRoutes.enUk,
        showGeotabSubscribe: true,
        isEnglish: true,
        isEuLanguage: true,
        enableSalesForceExtraField: {
            requestDemo: true,
            contactUsForm: true,
            platformPartnersForm: true,
        },
        flagPath: CountryFlags.uk,
        locale: 'en_GB',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: true,
    },
    {
        id: 3,
        code: LanguageCode.frCa,
        alias: '',
        dropDownOrder: 3,
        showInDropDown: true,
        urlPrefix: '/fr-ca/',
        tempUrlPrefix: '/fr-ca/',
        name: 'Canada (Français)',
        region: languageRegions.America,
        routes: languageRoutes.frCa,
        dayjsLanguageCode: frCa,
        showGeotabSubscribe: false,
        isEnglish: false,
        isEuLanguage: false,
        formDefaults: {
            noIndex: true,
        },
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.canada,
        locale: 'fr_CA',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 4,
        code: LanguageCode.es,
        alias: '',
        dropDownOrder: 5,
        showInDropDown: true,
        urlPrefix: '/es/',
        name: 'Spain (Español)',
        region: languageRegions.Europe,
        routes: languageRoutes.es,
        dayjsLanguageCode: es,
        showGeotabSubscribe: true,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: true,
        enableSalesForceExtraField: {
            requestDemo: true,
            contactUsForm: true,
            platformPartnersForm: true,
        },
        flagPath: CountryFlags.spain,
        locale: 'es_ES',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 5,
        code: LanguageCode.de,
        alias: '',
        dropDownOrder: 6,
        showInDropDown: true,
        urlPrefix: '/de/',
        name: 'Germany (Deutsch)',
        region: languageRegions.Europe,
        routes: languageRoutes.de,
        dayjsLanguageCode: de,
        showGeotabSubscribe: true,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: true,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: true,
            platformPartnersForm: true,
        },
        flagPath: CountryFlags.germany,
        locale: 'de_DE',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 6,
        code: LanguageCode.it,
        alias: '',
        dropDownOrder: 7,
        showInDropDown: true,
        urlPrefix: '/it/',
        name: 'Italy (Italiano)',
        routes: languageRoutes.it,
        region: languageRegions.Europe,
        formDefaults: {
            noIndex: false,
        },
        dayjsLanguageCode: it,
        showGeotabSubscribe: true,
        isEnglish: false,
        isEuLanguage: true,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.italy,
        locale: 'it_IT',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 7,
        code: LanguageCode.frFr,
        alias: fr,
        dropDownOrder: 4,
        showInDropDown: true,
        urlPrefix: '/fr/',
        name: 'France (Français)',
        routes: languageRoutes.frFr,
        region: languageRegions.Europe,
        dayjsLanguageCode: fr,
        showGeotabSubscribe: true,
        formDefaults: {
            noIndex: false,
        },
        isEuLanguage: true,
        isEnglish: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: true,
            platformPartnersForm: true,
        },
        flagPath: CountryFlags.france,
        locale: 'fr_FR',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 8,
        code: LanguageCode.ptBr,
        alias: '',
        dropDownOrder: 8,
        showInDropDown: true,
        urlPrefix: '/pt-br/',
        name: 'Brasil (Português)',
        region: languageRegions.America,
        routes: languageRoutes.ptBr,
        dayjsLanguageCode: ptBr,
        showGeotabSubscribe: true,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.brazil,
        locale: 'pt_BR',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 9,
        code: LanguageCode.enAu,
        alias: '',
        dropDownOrder: 2,
        showInDropDown: true,
        urlPrefix: '/au/',
        name: 'Australia (English)',
        region: languageRegions.Australia,
        routes: languageRoutes.enAu,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: true,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: true,
            platformPartnersForm: true,
        },
        flagPath: CountryFlags.australia,
        locale: 'en_AU',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: true,
    },
    {
        id: 11,
        code: LanguageCode.esMx,
        alias: 'es-latam',
        dayjsLanguageCode: es,
        dropDownOrder: 10,
        showInDropDown: true,
        urlPrefix: '/es-latam/',
        name: 'Latin America (Español)',
        region: languageRegions.America,
        routes: languageRoutes.esMx,
        showGeotabSubscribe: true,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.mexico,
        locale: 'es_MX',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 12,
        code: LanguageCode.pl,
        alias: '',
        dropDownOrder: 11,
        showInDropDown: false,
        urlPrefix: '/pl/',
        name: 'Poland (Polski)',
        region: languageRegions.Europe,
        routes: languageRoutes.pl,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: true,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.poland,
        locale: 'pl_PL',
        mobileDemoBannerType: 'link',
        enableChatbox: false,
    },
    {
        id: 13,
        code: LanguageCode.nl,
        alias: '',
        dropDownOrder: 13,
        showInDropDown: true,
        urlPrefix: '/nl/',
        name: 'Nederlands (Nederlands)',
        routes: languageRoutes.nl,
        region: languageRegions.Europe,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.netherlands,
        locale: 'nl_NL',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: false,
    },
    {
        id: 14,
        code: LanguageCode.se,
        alias: '',
        dropDownOrder: 14,
        showInDropDown: true,
        urlPrefix: '/se/',
        name: 'Sweden (Svenska)',
        region: languageRegions.Europe,
        routes: languageRoutes.se,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.sweden,
        locale: 'sv_SE',
        mobileDemoBannerType: 'link',
        enableChatbox: false,
    },
    {
        id: 15,
        code: LanguageCode.ae,
        alias: '',
        dropDownOrder: 15,
        showInDropDown: true,
        urlPrefix: '/ae/',
        name: 'Middle East (English)',
        region: languageRegions.MiddleEast,
        routes: languageRoutes.ae,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: false,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.uae,
        locale: 'ar_AE',
        mobileDemoBannerType: 'link',
        enableChatbox: true,
    },
    {
        id: 16,
        code: LanguageCode.ie,
        alias: '',
        dropDownOrder: 16,
        showInDropDown: true,
        urlPrefix: '/ie/',
        name: 'Ireland (English)',
        region: languageRegions.Europe,
        routes: languageRoutes.ie,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: true,
        isEuLanguage: true,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.ireland,
        locale: 'en_IE',
        mobileDemoBannerType: 'link',
        enableChatbox: true,
    },
    {
        id: 17,
        code: LanguageCode.apac,
        alias: '',
        dropDownOrder: 17,
        showInDropDown: true,
        urlPrefix: '/apac/',
        name: 'APAC (English)',
        region: languageRegions.Asia,
        disableLanguageSelection: false,
        routes: languageRoutes.apac,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: false,
        },
        isEnglish: true,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.singapore,
        locale: 'en_SG',
        mobileDemoBannerType: 'popoutButton',
        enableChatbox: true,
    },
    {
        id: 18,
        code: LanguageCode.enCb,
        alias: '',
        dropDownOrder: 18,
        showInDropDown: false,
        urlPrefix: '/en-cb/',
        name: 'Caribbean (English)',
        region: languageRegions.America,
        routes: languageRoutes.enCb,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: true,
        },
        isEnglish: true,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.caribbean,
        locale: 'en-TT',
        mobileDemoBannerType: 'link',
        enableChatbox: true,
    },
    {
        id: 19,
        code: LanguageCode.idn,
        alias: '',
        dropDownOrder: 19,
        showInDropDown: true,
        urlPrefix: '/idn/',
        dayjsLanguageCode: id,
        name: 'Indonesia (Bahasa)',
        region: languageRegions.Asia, // update this value to show on region selector
        routes: languageRoutes.idn,
        showGeotabSubscribe: false,
        formDefaults: {
            noIndex: true,
        },
        isEnglish: true,
        isEuLanguage: false,
        enableSalesForceExtraField: {
            requestDemo: false,
            contactUsForm: false,
            platformPartnersForm: false,
        },
        flagPath: CountryFlags.indonesian,
        locale: 'id_ID',
        mobileDemoBannerType: 'link',
        enableChatbox: false,
    },
];

export function getLanguageById(languageId: number | undefined): Language | undefined {
    return languageId ? languages.find((language) => language.id === languageId) : undefined;
}

export function getLanguageByCode(languageCode: string): Language | undefined {
    return languages.find((language) => language.code === languageCode);
}

export function getLanguageNameById(languageId: number | undefined): string | undefined {
    const language = getLanguageById(languageId);
    return language && language.name;
}

export function getAliasByCode(languageCode: string): string | undefined {
    const language = getLanguageByCode(languageCode);
    return language ? language.alias || languageCode : languageCode;
}

export function getLanguageNameByCode(languageCode: string): string | undefined {
    const language = getLanguageByCode(languageCode);
    return language && language.name;
}

export function getDayJsCodeByLanguageCode(languageCode: string): string {
    const language = getLanguageByCode(languageCode);
    return (language && language.dayjsLanguageCode) || LanguageCode.en;
}

export function getLanguageUrlPrefixByCode(languageCode: string): string {
    const language = getLanguageByCode(languageCode);
    return (language && (language.tempUrlPrefix || language.urlPrefix)) || '/';
}

export function getLanguageUrlPrefixById(languageId?: number): string {
    const language = getLanguageById(languageId);
    return (language && language.urlPrefix) || '/';
}

export function getAdminLanguageUrlPrefixById(languageId?: number): string {
    const language = getLanguageById(languageId);
    return (language && (language.tempUrlPrefix || language.urlPrefix)) || '/';
}

export function getLanguageRouteByCodeAndRouteKey(
    languageCode: string,
    routeKey: LanguageRouteKey
): string | undefined {
    const language = getLanguageByCode(languageCode);
    return language ? language.routes[routeKey] : '/';
}

export function getLanguageRouteByIdAndRouteKey(routeKey: LanguageRouteKey, languageId?: number): string | undefined {
    const language = getLanguageById(languageId);
    return language ? language.routes[routeKey] : '/';
}

export function getLanguageRouteAndPrefixByCodeAndRouteKey(languageCode: string, routeKey: LanguageRouteKey): string {
    const language = getLanguageByCode(languageCode);
    return language
        ? routeKey
            ? `${language.tempUrlPrefix || language.urlPrefix}${language.routes[routeKey]}`
            : language.tempUrlPrefix || language.urlPrefix
        : '/';
}

export function getLanguageRouteAndPrefixByIdAndRouteKey(languageId?: number, routeKey?: LanguageRouteKey): string {
    const language = getLanguageById(languageId);
    return language
        ? routeKey
            ? `${language.tempUrlPrefix || language.urlPrefix}${language.routes[routeKey]}`
            : language.tempUrlPrefix || language.urlPrefix
        : '/';
}

export function getFormDefaults(languageId: number): { noIndex?: boolean } {
    const language = getLanguageById(languageId);
    return language ? language.formDefaults : {};
}

export function getPageRelativeUrl(languageId: number, slug: string): string {
    const languagePrefix = getAdminLanguageUrlPrefixById(languageId);
    const slugToAppend = slug === '/' ? '' : slug;
    return `${languagePrefix}${slugToAppend}`;
}

export function getArticleRelativeUrl(routeKey: LanguageRouteKey, languageId: number, slug: string): string {
    const languagePrefix = getAdminLanguageUrlPrefixById(languageId);
    const languageTypePrefix = getLanguageRouteByIdAndRouteKey(routeKey, languageId);
    const slugToAppend = slug === '/' ? '' : slug;
    return `${languagePrefix}${languageTypePrefix}${slugToAppend}`;
}

export function isEuLanguage(languageCode: string): boolean {
    const language = getLanguageByCode(languageCode);
    return (language && language.isEuLanguage) || false;
}

export function getAllEnglishLanguageCodes(): string[] {
    return languages.filter((language) => language.isEnglish === true).map((obj) => obj.code) || [];
}

//regions - Array of regions to filter languages
export function getLanguagesByRegions(regions: string[]): Language[] {
    return languages
        .sort((first, second) => first.name.localeCompare(second.name))
        .filter(({ region, disableLanguageSelection }) => regions.includes(region) && !disableLanguageSelection);
}

export function getFlagPathByCode(languageCode: string): string | undefined {
    return getLanguageByCode(languageCode)?.flagPath;
}

export function getLocaleByCode(languageCode: string): string | undefined {
    return getLanguageByCode(languageCode)?.locale;
}
