import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';

function getEmotionCache(): EmotionCache {
    const cache = createCache({
        key: 'css',
        insertionPoint:
            typeof document !== 'undefined'
                ? (document.getElementById('emotion-insertion-point') as HTMLElement)
                : undefined,
    });
    cache.compat = true;

    return cache;
}

export const emotionCache = getEmotionCache();

interface EmotionCacheProviderProps {
    children: React.ReactNode;
}

export function EmotionCacheProvider({ children }: EmotionCacheProviderProps): JSX.Element {
    return <CacheProvider value={emotionCache}>{children}</CacheProvider>;
}
