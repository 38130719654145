import React from 'react';

export function NewTabIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            data-testid='externalIcon'
            width='15'
            height='15'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='M21 7V1H15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M21 1L7.66663 14.3333'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.3333 5H2.33333C1.59695 5 1 5.59696 1 6.33334V19.6667C1 20.403 1.59695 21 2.33333 21H15.6667C16.403 21 17 20.403 17 19.6667V11.6667'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
