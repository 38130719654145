import GeneralInquiriesHeroImage from '@images/StaticPages/ContactUs/general-inquiries-hero-image@2x.jpg';
import CustomerSupportIcon from '@images/StaticPages/ContactUs/icon-customer-support.svg';
import GeneralInquiriesIcon from '@images/StaticPages/ContactUs/icon-general-inquiries.svg';
import SalesInquiriesIcon from '@images/StaticPages/ContactUs/icon-sales-inquiry.svg';
import ResellerSuport from '@images/StaticPages/ContactUs/reseller-support.jpg';
import SalesInquiryHeroImage from '@images/StaticPages/ContactUs/sales-inquiry-hero-image2x.jpg';
import FaqHeroImage from '@images/StaticPages/Faq/faqs-hero-image@2x.jpg';
import PhoneSoftwareEnergy from '@images/StaticPages/SubscribePage/geotab-phone-software-energy.jpg';
import SubscribePageBannerImage from '@images/StaticPages/SubscribePage/fleet-newsletter-landing-page-image@2x.png';
import SubscribeCta from '@images/StaticPages/SubscribePage/geotab-subscribe-cta.jpg';
import IconSubscribeSuccess from '@images/StaticPages/SubscribePage/icon-form-submission-success.svg';
import SubscribeFeatureImage from '@images/StaticPages/SubscribePage/subscribe-feature-image@2x.jpg';
import IconFirmware from '@images/StaticPages/SupportDocuments/icon-firmware.svg';
import IconHardware from '@images/StaticPages/SupportDocuments/icon-hardware.svg';
import IconHarnessAdapter from '@images/StaticPages/SupportDocuments/icon-harness-and-adapter.svg';
import IconIoxAddOn from '@images/StaticPages/SupportDocuments/icon-iox-and-add-on.svg';
import BlogGraphic from '@images/StaticPages/Videos/blog-graphic.svg';
import ForumsGraphic from '@images/StaticPages/Videos/forums-graphic.svg';
import WhitePapersGraphic from '@images/StaticPages/Videos/white-papers-graphic.svg';

export const contactUs = {
    generalInquiriesHeroImage: GeneralInquiriesHeroImage,
    customerSupportIcon: CustomerSupportIcon,
    generalInquiriesIcon: GeneralInquiriesIcon,
    salesInquiriesIcon: SalesInquiriesIcon,
    resellerSupport: ResellerSuport,
    salesInquiryHeroImage: SalesInquiryHeroImage,
};

export const faq = {
    faqHeroImage: FaqHeroImage,
};

export const subscribePage = {
    phoneSoftwareEnergy: PhoneSoftwareEnergy,
    subscribePageBannerImage: SubscribePageBannerImage,
    subscribeCta: SubscribeCta,
    iconSubscribeSuccess: IconSubscribeSuccess,
    subscribeFeatureImage: SubscribeFeatureImage,
};

export const supportDocuments = {
    iconFirmware: IconFirmware,
    iconHardware: IconHardware,
    iconHarnessAdapter: IconHarnessAdapter,
    iconIoxAddOn: IconIoxAddOn,
};

export const videos = {
    blogGraphic: BlogGraphic,
    forumsGraphic: ForumsGraphic,
    whitePapersGraphic: WhitePapersGraphic,
};
