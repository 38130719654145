import React from 'react';
import { Dialog, CircularProgress, Grid } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const classes = {
    container: {
        minWidth: '20rem',
        minHeight: '15rem',
    },
};

interface LoadingDialogProps {
    open: boolean;
}

export function LoadingDialog({ open }: LoadingDialogProps): JSX.Element {
    const handleClose = (event: unknown, reason: 'escapeKeyDown' | 'backdropClick'): boolean | undefined => {
        if (reason === 'backdropClick') {
            return false;
        }
        if (reason === 'escapeKeyDown') {
            return false;
        }
    };

    return (
        <Dialog aria-labelledby='loading-dialog-title' open={open} onClose={handleClose}>
            <h2 style={visuallyHidden} id='loading-dialog-title'>
                Information is loading
            </h2>
            <Grid container direction='column' css={classes.container} justifyContent='center' alignItems='center'>
                <Grid>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Dialog>
    );
}
