export function hasValue(value: unknown): boolean {
    if (typeof value === 'string') {
        return value.trim().length > 0;
    }

    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else {
            return !!value && Object.keys(value).length > 0;
        }
    }

    if (value === undefined || value === false) {
        return false;
    }

    return true;
}

interface HasLengthOptions {
    max?: number;
    min?: number;
}

export type HasLengthPayload = HasLengthOptions | number;

export function isLengthValid(length: HasLengthPayload, value: unknown): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    const trimmedValue = value.trim();

    if (typeof length === 'number') {
        return trimmedValue.length === length;
    }

    const { max, min } = length;
    let valid = true;

    if (typeof max === 'number' && trimmedValue.length > max) {
        valid = false;
    }

    if (typeof min === 'number' && trimmedValue.length < min) {
        valid = false;
    }

    return valid;
}
