import { resolveImagePath, resolveGeneralFilePath, mediaUrlPrefixToken, generalFileUrlPrefixToken } from './gallery';

// eslint-disable-next-line no-useless-escape
const isInternalLinkRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^((https?:\/\/(www|webtest3|webtest2)\.geotab\.com)|\/).*/
);

// Example: /static/test.jpg
// eslint-disable-next-line no-useless-escape
const hasDotAfterSlashRegex = new RegExp(/(\/)([^\/]*)(\.)([^\/]*)([^\/]$)/);

// eslint-disable-next-line no-useless-escape
export const matchesWebsitePattern = (value: string): boolean =>
    value.search(
        // eslint-disable-next-line no-useless-escape
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    ) >= 0;

// eslint-disable-next-line no-useless-escape
export const isYoutubeURL = new RegExp(
    /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi
);

export function escapeSpecialChar(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function ensureInternalURLTrailingSlash(inputString: string): string {
    if (
        !isAnchorLink(inputString) &&
        !isResource(inputString) &&
        !isQueryStringLink(inputString) &&
        isInternalLink(inputString) &&
        !lastCharacterIsSlash(inputString)
    ) {
        return `${inputString}/`;
    } else {
        return inputString;
    }
}

export function formatString(string: string, concatString: string): string {
    return `${string}${concatString ? `-${concatString}` : ''}`
        .toLowerCase()
        .trim()
        .replace(/ /g, '-')
        .replace(/:/g, '')
        .replace(/,/g, '')
        .replace(/&/g, 'and');
}

export function lastCharacterIsSlash(inputString: string): boolean {
    return inputString && inputString.length > 0 && inputString[inputString.length - 1] === '/' ? true : false;
}

export function isInternalLink(inputString: string): boolean {
    return isInternalLinkRegex.test(inputString);
}

export function isInternalOrBaseLink(inputString: string, baseUrl: string): boolean {
    return isInternalLinkRegex.test(inputString) || inputString.includes(baseUrl);
}

function isResource(inputString: string): boolean {
    return hasDotAfterSlashRegex.test(inputString);
}

function isAnchorLink(inputString: string): boolean {
    return inputString && inputString.length > 0 && inputString.includes('#') ? true : false;
}

function isQueryStringLink(inputString: string): boolean {
    return inputString && inputString.length > 0 && inputString.includes('?') ? true : false;
}

export function ensureGeotabTitle(inputString: string): string {
    if (inputString && inputString.length > 0 && !inputString.includes('| Geotab')) {
        return `${inputString} | Geotab`;
    } else {
        return inputString;
    }
}

export function classCreator(
    classes: { [key: string]: string },
    type: string,
    literal = ''
): (literal: string) => string {
    const defaultName = 'default';
    classes[defaultName + literal];
    return function createClass(literal: string) {
        return `${classes[defaultName + literal] || ''} ${classes[type + literal] || ''}`;
    };
}

export function ensureHttps(url: string): string {
    if (!url) {
        return url;
    }

    if (
        url &&
        (url.toLowerCase().startsWith('http://') ||
            url.toLowerCase().startsWith('https://') ||
            url.toLowerCase().startsWith('mailto:') ||
            url.startsWith('#'))
    ) {
        return url;
    } else {
        return `https://${url}`;
    }
}

export function ensureEmbededYoutubeURL(videoURL: string): string {
    if (videoURL.includes('youtu.be') || videoURL.includes('youtube.com')) {
        const youtubeId = ensureHttps(videoURL).replace(isYoutubeURL, '$1');
        return `https://www.youtube.com/embed/${youtubeId}?enablejsapi=1`;
    } else {
        return videoURL;
    }
}

export function pluralizeEntityType(entityTypeName: string): string {
    if (!entityTypeName) {
        return entityTypeName;
    }

    return entityTypeName.toLowerCase().endsWith('y')
        ? `${entityTypeName.substring(0, entityTypeName.length - 1)}ies`
        : `${entityTypeName}s`;
}

function getPhoneExtensionIfExists(phoneNumber: string): string {
    return phoneNumber.slice(0, Math.max(phoneNumber.length - 10, 0));
}

function getPhoneNumberWithoutExtension(phoneNumber: string): string {
    return phoneNumber.slice(Math.max(phoneNumber.length - 10, 0), phoneNumber.length).padEnd(10, ' ');
}

export function decoratedPhoneNumber(phoneNumber: string): string {
    if (phoneNumber) {
        phoneNumber = phoneNumber.toString();
        const extension = getPhoneExtensionIfExists(phoneNumber);
        const phoneNumberWithoutExtension = getPhoneNumberWithoutExtension(phoneNumber);
        const separatedPhoneNumber = phoneNumberWithoutExtension.match(/(.{3})(.{3})(.{4})/);
        return `${extension.length > 0 ? `+${extension} ` : ''}(${separatedPhoneNumber && separatedPhoneNumber[1]}) ${
            separatedPhoneNumber && separatedPhoneNumber[2]
        }-${separatedPhoneNumber && separatedPhoneNumber[3]}`;
    } else {
        return '';
    }
}

export function undecoratePhoneNumber(phoneNumber: string): string {
    const phoneNumberFormat = /(\+\d*)?\(\d{3}\) (\d{3})-(\d{4})/;
    return phoneNumberFormat.test(phoneNumber) ? phoneNumber.replace(/\D/g, '') : '';
}

export function replaceValueInString(lookupString: string, replacementString: string, inputString?: string): string {
    if (inputString && inputString.includes(lookupString)) {
        const replaceRegex = new RegExp(lookupString, 'gi');
        return inputString.replace(replaceRegex, replacementString);
    }
    return inputString || '';
}

export function getPrefixStringWithBaseUrlFunction(
    baseUrl: string,
    mediaUrlPrefix: string,
    generalFileUrlPrefix: string
): (string?: string) => string {
    if (baseUrl && baseUrl.endsWith('/')) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    return (string?: string) => {
        if (typeof string === 'string') {
            if (string.startsWith('http://') || string.startsWith('https://')) {
                return string;
            }
            if (mediaUrlPrefix && string.includes(mediaUrlPrefixToken)) {
                return encodeURI(resolveImagePath(mediaUrlPrefix, string));
            }
            if (generalFileUrlPrefix && string.includes(generalFileUrlPrefixToken)) {
                return encodeURI(resolveGeneralFilePath(generalFileUrlPrefix, string));
            }
            if (baseUrl && !string.startsWith(baseUrl)) {
                const stringToPrefix = !string.startsWith('/') ? `/${string}` : string;
                return encodeURI(`${baseUrl}${stringToPrefix}`);
            }
        }

        return encodeURI(string as string);
    };
}

export function capitalizeFirstLetter(string: string): string {
    return string && string.length > 0 ? string[0].toUpperCase() + string.slice(1) : string;
}

export function trimEndingLetterS(string: string): string {
    return string && (string[string.length - 1] === 's' || string[string.length - 1] === 'S')
        ? string.slice(0, string.length - 1)
        : string;
}

export function getRandomString(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function tryDecodeURI(inputString: string): string {
    try {
        return decodeURI(inputString);
    } catch (error) {
        return inputString;
    }
}

export function formatUrlString(string: string): string {
    return string.toLowerCase().replace(/\s/g, '%20');
}

export function removeUrlParameters(urlString: string): string {
    return isQueryStringLink(urlString) ? urlString.split('?')[0] : urlString;
}

export function removeStartingSlash(urlString: string): string {
    return urlString.startsWith('/') ? urlString.substring(1) : urlString;
}

export const removeElementsFromHtmlByTag = (content: string, ...tags: string[]): string => {
    if (content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        if (Array.isArray(tags)) {
            tags.forEach((tag) => {
                const elements = doc.body.getElementsByTagName(tag);
                Array.from(elements).forEach((element) => element.remove());
            });
        }
        return doc.body.innerHTML.toString();
    } else {
        return content;
    }
};

export function lastCharacterIsComma(inputString: string): boolean {
    return inputString && inputString.length > 0 && inputString[inputString.length - 1] === ',' ? true : false;
}

export function removeSubstringByDelimiter(inputString: string, removeIndex: number, delimiter: string): string {
    const stringPartsArr = inputString.split(delimiter);
    stringPartsArr.splice(removeIndex, 1);

    return stringPartsArr.join(delimiter);
}

export function removeHTMLTags(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
}
