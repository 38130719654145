import React from 'react';
import './index.css';
import './animate.min.css';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { Routing } from './Routing';
import { ThemeProvider } from '@mui/material/styles';
import { NotificationProvider } from '@contexts/NotificationContext';
import { appTheme } from './AppStyle';
import { StaticValuesProvider } from '@stateManagement/StaticValuesContext';
import { getStaticValues } from '@stateManagement/helper/initialState';
import { CheckMobile } from '@components/CheckMobile';
import { TranslationLoader } from '@components/translationComponents/TranslationLoader';
import { AppInitialData } from '@models/initialData';
import { Provider } from 'jotai';
import { myAdminUserAtom } from '@atoms/authentication';
import { AuthenticationSettings, MyAdminUser } from '@models/authentication';
import { faqsAtom } from '@atoms/faqs';
import { navbarAtom, footerAtom, isNavbarDisabledAtom, isFooterDisabledAtom } from '@atoms/navigation';
import { defaultNavbar, defaultFooter } from '@components/EditorEntity/navigationGalleryContent';
import { isMobileAtom, selectedLanguageCodeAtom } from '@atoms/appSettings';
import { EmotionCacheProvider } from './emotionCache';
import { appStore } from '@stateManagement/jotaiStore';
import { FontPreloadPageHelmet } from '@components/FontPreloadPageHelmet';
import { GlobalStyles } from '@mui/material';
import { SkipToContentLink } from '@components/SkipToContentLink';

interface AppProps {
    location: string;
    initialData: AppInitialData;
    baseUrl: string;
    fullUrl: string | null;
    isMobile: boolean;
    myAdminBaseApiUrl: string;
    authenticationSettings: AuthenticationSettings;
    myAdminUser: MyAdminUser;
    mediaUrlPrefix: string;
    generalFileUrlPrefix: string;
}

export function App({
    location,
    initialData,
    baseUrl,
    fullUrl,
    isMobile,
    myAdminBaseApiUrl,
    authenticationSettings,
    myAdminUser,
    mediaUrlPrefix,
    generalFileUrlPrefix,
}: AppProps): JSX.Element {
    const staticValues = getStaticValues(
        baseUrl,
        mediaUrlPrefix,
        generalFileUrlPrefix,
        myAdminBaseApiUrl,
        authenticationSettings,
        initialData,
        fullUrl
    );

    appStore.set(faqsAtom, initialData.faqs);
    appStore.set(myAdminUserAtom, myAdminUser);
    appStore.set(navbarAtom, initialData.menuNavigation || defaultNavbar);
    appStore.set(isNavbarDisabledAtom, initialData?.page?.menuNavigationDisabled || false);
    appStore.set(footerAtom, initialData.footerNavigation || defaultFooter);
    appStore.set(isFooterDisabledAtom, initialData?.page?.footerNavigationDisabled || false);
    appStore.set(isMobileAtom, isMobile);
    appStore.set(selectedLanguageCodeAtom, initialData.selectedLanguage);

    const app = (
        <>
            <FontPreloadPageHelmet />
            <GlobalStyles styles={{ 'img[src=""], img:not([src])': { visibility: 'hidden' } }} />
            <SkipToContentLink />
            <Routing />
            <CheckMobile />
        </>
    );

    const appWithProviders = (
        <EmotionCacheProvider>
            <ThemeProvider theme={appTheme}>
                <StaticValuesProvider staticValues={staticValues}>
                    <Provider store={appStore}>
                        <NotificationProvider>
                            <TranslationLoader selectedLanguage={initialData.selectedLanguage}>{app}</TranslationLoader>
                        </NotificationProvider>
                    </Provider>
                </StaticValuesProvider>
            </ThemeProvider>
        </EmotionCacheProvider>
    );

    return typeof window === 'undefined' ? (
        <StaticRouter location={location}>{appWithProviders}</StaticRouter>
    ) : (
        <BrowserRouter>{appWithProviders}</BrowserRouter>
    );
}
