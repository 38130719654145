import React from 'react';

export function RightArrowIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='8'
            height='13'
            viewBox='0 0 8 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            color='#FFFFFF'
            {...props}
        >
            <path d='M1 1.5L6 6.5L1 11.5' stroke='currentColor' strokeWidth='1.5' />
        </svg>
    );
}
