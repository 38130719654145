import React, { useMemo, useState } from 'react';
import { RegionSelectionDialogStyles as classes } from './styleObjects/RegionSelectionDialogStyles';
import { languageRegions, getLanguagesByRegions } from '@helpers/languages';
import { Grid, Button, IconButton, Dialog } from '@mui/material';
import { LanguageList } from '@components/RegionSelection/LanguageList';
import { LanguageListIcon } from '@components/IconComponents/LanguageListIcon';
import { LanguageListMobileIcon } from '@components/IconComponents/LanguageListMobileIcon';
import { useAtom } from 'jotai';
import { isMobileAtom, isRegionSelectionDialogOpenAtom, selectedLanguageCodeAtom } from '@atoms/appSettings';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';
import { Heading, Select } from '@web-for-marketing/react-ui';
import { CloseIcon } from '@icon/CloseIcon';
import { getLanguageUrlPrefixByCode } from '@helpers/languages';

const global = getLanguagesByRegions([languageRegions.Global]);
const americanRegionLanguages = getLanguagesByRegions([languageRegions.America]);
const europeRegionLanguages = getLanguagesByRegions([languageRegions.Europe]);
const otherRegionLanguages = getLanguagesByRegions([
    languageRegions.Asia,
    languageRegions.Australia,
    languageRegions.MiddleEast,
]);
const allRegionsOptions = [
    ...otherRegionLanguages,
    ...europeRegionLanguages,
    ...americanRegionLanguages,
    ...global,
].map((region) => ({
    value: region.code,
    content: region.name,
}));

export function RegionSelectionDialog(): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [isRegionSelectionDialogOpen, setIsRegionSelectionDialogOpen] = useAtom(isRegionSelectionDialogOpenAtom);
    const [languageCode] = useAtom(selectedLanguageCodeAtom);
    const { selectedLanguageTranslation } = useTranslation();
    const regionSelectionTranslation = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'regionSelection');
    }, [selectedLanguageTranslation]);
    const [selectedRegion, setSelectedRegion] = useState(languageCode);
    const globalHeader = regionSelectionTranslation.regions.global;

    return (
        <Grid>
            <Dialog
                open={isRegionSelectionDialogOpen}
                onClose={() => setIsRegionSelectionDialogOpen(false)}
                maxWidth={!isMobile ? 'md' : 'sm'}
                fullWidth={!isMobile ? false : true}
                aria-labelledby='region-selection-title'
            >
                <Grid container justifyContent='flex-end'>
                    <IconButton
                        onClick={() => setIsRegionSelectionDialogOpen(false)}
                        aria-label='close regions popup'
                        data-testid='close-button'
                        css={classes.closeButton}
                    >
                        <CloseIcon css={classes.closeIcon} />
                    </IconButton>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' flexDirection='column'>
                    <Grid item data-testid='icon-list'>
                        {!isMobile ? (
                            <LanguageListIcon css={classes.iconLanguageList} />
                        ) : (
                            <LanguageListMobileIcon css={classes.iconLanguageListMobile} />
                        )}
                    </Grid>
                    <Heading
                        css={classes.dialogHeading}
                        variant='h3'
                        weight='regular'
                        component='h3'
                        id='region-selection-title'
                    >
                        {regionSelectionTranslation.title}
                    </Heading>
                </Grid>
                {!isMobile ? (
                    <Grid container columnSpacing={3} direction='row' css={classes.contentContainer}>
                        <Grid item md={4}>
                            <LanguageList title={globalHeader ? globalHeader : 'Global'} languages={global} />
                            <LanguageList
                                title={regionSelectionTranslation.regions.northSouthAmerica}
                                languages={americanRegionLanguages}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <LanguageList
                                title={regionSelectionTranslation.regions.europe}
                                languages={europeRegionLanguages}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <LanguageList
                                title={regionSelectionTranslation.regions.asiaAustraliaMiddleEast}
                                languages={otherRegionLanguages}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction='column' textAlign='center' css={classes.contentContainer}>
                        <Grid item css={classes.mobileButtonsContainer}>
                            <Select
                                id='regions-section'
                                label='Regions'
                                hiddenLabel
                                tooltipText='Changes the selected region'
                                value={selectedRegion}
                                onChange={setSelectedRegion}
                                options={allRegionsOptions}
                                css={classes.selectRegionsDropdown}
                            />
                            <Button
                                type='submit'
                                variant='contained'
                                href={getLanguageUrlPrefixByCode(selectedRegion)}
                                css={classes.continueButton}
                            >
                                Continue
                            </Button>
                            <Button onClick={() => setIsRegionSelectionDialogOpen(false)} variant='text'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Dialog>
        </Grid>
    );
}
