import React, { useState, createContext, useContext, useMemo } from 'react';

type Translation = Record<string, unknown>;

interface TranslationProviderProps {
    children: React.ReactNode;
    translation: Translation;
}

interface TranslationContextValue {
    selectedLanguageTranslation: Translation;
    setSelectedLanguageTranslation: React.Dispatch<React.SetStateAction<Translation>>;
}

const TranslationContext = createContext<TranslationContextValue | undefined>(undefined);

export const TranslationProvider = ({ translation, children }: TranslationProviderProps): JSX.Element => {
    const [selectedLanguageTranslation, setSelectedLanguageTranslation] = useState(translation);
    const contextValue = useMemo(
        () => ({ selectedLanguageTranslation, setSelectedLanguageTranslation }),
        [selectedLanguageTranslation]
    );

    return <TranslationContext.Provider value={contextValue}>{children}</TranslationContext.Provider>;
};

export const useTranslation = (): TranslationContextValue => {
    const context = useContext(TranslationContext);
    if (context === undefined) {
        throw new Error('useTranslation must be used within a TranslationProvider');
    }

    return context;
};
