import { languageRouteTypes } from '@helpers/languageRoutes';
import { categoryFormHelper } from '@helpers/categoryForm';
import { authorFormHelper } from '@helpers/authorForm';
import { tagFormHelper } from '@helpers/tagForm';
import { BlogEntity } from '@models/blog';
import { BlogSettingsEntity } from '@models/blogForm';

export const blogEntityType: Record<BlogEntity, BlogSettingsEntity> = {
    category: {
        pluralName: 'Categories',
        singularName: 'Category',
        baseApi: 'category',
        publicBaseUrl: 'blog/category',
        formData: categoryFormHelper,
        routeType: languageRouteTypes.CATEGORY,
    },
    author: {
        pluralName: 'Authors',
        singularName: 'Author',
        baseApi: 'author',
        publicBaseUrl: 'blog/author',
        formData: authorFormHelper,
        routeType: languageRouteTypes.AUTHOR,
    },
    tag: {
        pluralName: 'Tags',
        singularName: 'Tag',
        baseApi: 'tag',
        publicBaseUrl: 'tag',
        formData: tagFormHelper,
        routeType: languageRouteTypes.TAG,
    },
};
